import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ChangeEvent, useEffect, useState } from "react";
import { showError, showToast } from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import { useAddCommunityMutation } from "../../services/community";
import { isString } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";

const AddCommunity = () => {
  const navigate = useNavigate();
  const [addCommunity] = useAddCommunityMutation();

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(40, "Maximum 40 character are allowed")
        .min(3, "Minimum 2 characters are required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const body = {
        name: formik?.values?.title,
        image: image,
      };

      if (!image) {
        setError(true);
        return;
      }

      try {
        const response = await addCommunity(body).unwrap();
        if (response?.statusCode === 200) {
          showToast("Community Added Successfully");
          formik.resetForm();
          navigate("/manage-community");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Community</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-community");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CloseIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.svg"
                              alt=""
                            />
                            <figure className="camera_btn">
                              <img
                                src="/static/images/camera_icon.svg"
                                alt=""
                              />
                            </figure>
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Community Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      inputProps={{ maxLength: 40 }}
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCommunity;
