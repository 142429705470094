import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import { showError, showToast } from "../../../constants/toast";
import { useAppDispatch } from "../../../hooks/store";
import { temporaryToken } from "../../../reducers/authSlice";
import {
  usePostVerifyOtpMutation,
  usePostForgotPasswordMutation,
} from "../../../services/auth";
import Loader from "../../../constants/Loader";

const otpStyle = {
  width: "60px",
  height: "60px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const VerifyOtp = () => {
  const location = useLocation();
  const { state } = location;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [verifyOptMutation, verifyOptMutationData] = usePostVerifyOtpMutation();
  const [forgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();

  const [otp, setOtp] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(59);

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      const body = {
        email: state?.email,
        code: otp,
      };

      try {
        const response = await verifyOptMutation(body).unwrap();
        if (response?.statusCode === 200) {
          dispatch(temporaryToken({ tempToken: response?.data }));
          navigate("/resetpassword", { replace: true });
        }
      } catch (error: any) {
        if (error?.data?.message) {
          showError(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    let body = {
      email: state?.email,
    };
    try {
      const response = await forgotPasswordMutation(body).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={verifyOptMutationData?.isLoading} />

      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>

        <div className="auth_box">
          <Box sx={{ mb: 4, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/forgotpassword")}
              sx={{ p: 0, position: "absolute", left: 0 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">Verify Account</Typography>
            <Typography>
              Please enter the verification code we sent to your phone number
            </Typography>
          </Box>

          <FormControl className="opt_fields" sx={{ width: "100%" }}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={otpStyle}
              inputType="tel"
            />
          </FormControl>
          <Box sx={{ pt: 3 }}>
            <Button
              className="btn btn_primary"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Verify OTP
            </Button>
          </Box>
          <Box
            className="auth_foot"
            sx={{
              textAlign: "center",
              pt: 3,
            }}
          >
            {countDown === 0 ? (
              <Box
                sx={{
                  textAlign: "center",
                  pt: 2,
                }}
              >
                <Typography
                  className="anchor_link"
                  sx={{ cursor: "pointer" }}
                  onClick={handleResendOtp}
                >
                  Resend Otp
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  sx={{
                    color: "grey",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  The verify code will expire in{" "}
                  <p style={{ margin: 0, color: "#7fcbbf", paddingLeft: 3 }}>
                    00 : {countDown}
                  </p>
                </Typography>
              </Box>
            )}
          </Box>
        </div>
      </Container>
    </Box>
  );
};

export default VerifyOtp;
