import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  NativeSelect,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import {
  useLazyGetCustomerSupportQuery,
  useAddReplyMutation,
} from "../../services/main";
import SearchBar from "../../components/SearchBar";
import ReplyIcon from "@mui/icons-material/Reply";
import { isString, isValidInput } from "../../utils/validations";
import { Support } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import moment from "moment";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerSupport = () => {
  const [getAllSupportQuery, { isLoading }] = useLazyGetCustomerSupportQuery();
  const [addQueryReply] = useAddReplyMutation();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [allSupport, setAllSupport] = useState<Support[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [value, setValue] = React.useState(0);
  const [openQueryModal, setOpenQueryModal] = React.useState(false);
  console.log(value, "VALUE");
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  function createData1(
    UserName: string,
    UserPhone: string,
    UserEmail: string,
    Queries: string,
    DateTime: string
  ) {
    return { UserName, UserPhone, UserEmail, Queries, DateTime };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows1 = [
    createData1(
      "Gurinder Singh",
      "9876543210",
      "gurinder@apptunix.com",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      "04-10-2023 / 10:00AM"
    ),
  ];

  const handleClose = () => {
    setOpenQueryModal(false);
    formik.setFieldError("message", "");
  };
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required")
        .matches(/^[\s\S]*$/, "All special characters are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        reply: formik.values.message,
      };
      try {
        const response = await addQueryReply({
          queryId: selectedId,
          body,
        }).unwrap();
        showToast(response?.message || "");
        setOpenQueryModal(false);
        formik.setFieldValue("message", "");
        formik.setFieldError("message", "");
        getCustomerSupport(0);
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  const getCustomerSupport = async (val: number) => {
    try {
      const response = await getAllSupportQuery({
        query: debouncedSearchTerm.trim(),
        page: page,
        isReplied: val,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllSupport(response?.data?.data || []);
        setTotalCount(response?.data?.totalPages);
      } else {
        setAllSupport([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleClickOpen = (id: string) => {
    setOpenQueryModal(true);
    setSelectedId(id);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    console.log(newValue, "NWWW");
    getCustomerSupport(newValue);
  };

  useEffect(() => {
    getCustomerSupport(0);
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Customer Support</h1>
        </div>
        <Box className="custom_tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="Queries Pending" {...a11yProps(0)} />
            <Tab label="Queries Resolved" {...a11yProps(1)} />
          </Tabs>

          <Card className="cards">
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">
                    <Checkbox {...label} />
                  </TableCell> */}
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>User Phone</TableCell>
                      <TableCell>User Email</TableCell>
                      <TableCell>Queries</TableCell>
                      <TableCell>Date/Time</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allSupport?.length ? (
                      allSupport?.map((row, i) => (
                        <TableRow>
                          {/* <TableCell align="center">
                          <Checkbox {...label} />
                        </TableCell> */}
                          <TableCell align="center">{i + 1}</TableCell>
                          <TableCell>{row?.userId?.name || "-"}</TableCell>
                          <TableCell>{`${row?.userId?.countryCode || ""} ${
                            row?.userId?.phone || "-"
                          }`}</TableCell>
                          <TableCell>{row.userId?.email || "-"}</TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "pre-wrap",
                              overflow: "hidden",
                            }}
                          >
                            {row?.query || ""}
                          </TableCell>
                          <TableCell>
                            {moment(row?.createdAt).format("MM-D-YY / h:mm A")}
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() => handleClickOpen(row?._id)}
                              >
                                <ReplyIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No Query Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>User Phone</TableCell>
                      <TableCell>User Email</TableCell>
                      <TableCell>Queries</TableCell>
                      <TableCell>Date/Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allSupport?.length ? (
                      allSupport?.map((row, i) => (
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userId?.name || "-"}</TableCell>
                          <TableCell>{`${row?.userId?.countryCode || ""} ${
                            row?.userId?.phone || "-"
                          }`}</TableCell>
                          <TableCell>{row.userId?.email || "-"}</TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "pre-wrap",
                              overflow: "hidden",
                            }}
                          >
                            {row.query}
                          </TableCell>
                          <TableCell>
                            {moment(row?.createdAt).format("MM-D-YY / h:mm A")}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No Query Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Card>
        </Box>
        <Pagination
          setPage={setPage}
          module={allSupport}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalCount}
        />
      </div>

      <Dialog
        open={openQueryModal}
        onClose={handleClose}
        className="revert_dialog"
      >
        <DialogContent>
          <Typography className="dialog_title">Revert Query</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                name="message"
                type="text"
                placeholder="Revert Query"
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.message}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isValidInput(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.message && formik.errors.message}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_danger" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default CustomerSupport;
