import { END_POINTS } from "../constants/url";
import { Subscription } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalPages: number;
    count: number;
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page: number;
  query: string;
};

type AddSubscriptionBody = {
  name: string;
  price: string;
  description: string;
  // monthlyPrice: string;
  // quarterlyPrice: string;
  // yearlyPrice: string;
  planType: number;
  subscriptionType: number;
  features: any;
};

type UpdateParams = {
  subsId: string | undefined;
  body: {
    name: string;
    price: string;
    description: string;
    // monthlyPrice: string;
    // quarterlyPrice: string;
    // yearlyPrice: string;
    features: any;
    planType: number;
    subscriptionType: number;
  };
};

export const subscriptionApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalCount: number;
          data: Subscription[];
        };
      },
      GetUserParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.subscription + "?page=" + page + "&search=" + query,
        method: "GET",
      }),
    }),

    addSubscription: builder.mutation<
      { message: string; statusCode: number; data: Subscription },
      AddSubscriptionBody
    >({
      query: (body) => ({
        url: END_POINTS.subscription,
        method: "POST",
        body,
      }),
    }),

    getSubscriptionById: builder.query<
      CommonResponseType & { data: Subscription },
      { subId: string | undefined }
    >({
      query: ({ subId }) => ({
        url: `${END_POINTS.subscription}/${subId}`,
        method: "GET",
      }),
    }),

    changeSubscriptionStatus: builder.mutation<
      { message: string; statusCode: number },
      { subsId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, subsId }) => ({
        url: `${END_POINTS.subscription}/${subsId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteSubscription: builder.mutation<
      CommonResponseType,
      { subsId: string }
    >({
      query: ({ subsId }) => ({
        url: `${END_POINTS.subscription}/${subsId}`,
        method: "DELETE",
      }),
    }),

    updateSubscription: builder.mutation<
      CommonResponseType & { data: Subscription[] },
      UpdateParams
    >({
      query: ({ body, subsId }) => ({
        url: `${END_POINTS.subscription}/${subsId}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAllSubscriptionsQuery,
  useAddSubscriptionMutation,
  useLazyGetSubscriptionByIdQuery,
  useChangeSubscriptionStatusMutation,
  useDeleteSubscriptionMutation,
  useUpdateSubscriptionMutation,
} = subscriptionApi;
