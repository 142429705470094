import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBQ9YPHiu6LztC98-znQpSRNtzpAMpSIno",
  authDomain: "allergyproof-e8cc2.firebaseapp.com",
  projectId: "allergyproof-e8cc2",
  storageBucket: "allergyproof-e8cc2.appspot.com",
  messagingSenderId: "968270175879",
  appId: "1:968270175879:web:9711b969f7e66e949d6222",
  measurementId: "G-RB3XZD4QCH",
};

const app = initializeApp(firebaseConfig);
export default app;
