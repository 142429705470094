import { END_POINTS } from "../constants/url";
import { Subscription } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalPages: number;
    count: number;
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page: number;
  query: string;
};

type AddSubscriptionBody = {
  name: string;
  image: string;
  description: string;
  monthlyPrice: string;
  quarterlyPrice: string;
  yearlyPrice: string;
  features: any;
};

type UpdateParams = {
  subsId: string | undefined;
  body: {
    name: string;
    image: string;
    description: string;
    monthlyPrice: string;
    quarterlyPrice: string;
    yearlyPrice: string;
    features: any;
  };
};

type AddCommunityBody = {
  name: string;
  image: string;
};

type UpdateCommunityParams = {
  communityId: string | undefined;
  body: {
    name: string;
    image: string;
  };
};

export const subscriptionApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCommunity: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalcommunity: number;
          community: any;
        };
      },
      GetUserParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.community + "?page=" + page + "&search=" + query,
        method: "GET",
      }),
    }),

    addCommunity: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddCommunityBody
    >({
      query: (body) => ({
        url: END_POINTS.community,
        method: "POST",
        body,
      }),
    }),

    deleteCommunity: builder.mutation<
      CommonResponseType,
      { communityId: string }
    >({
      query: ({ communityId }) => ({
        url: `${END_POINTS.community}/${communityId}`,
        method: "DELETE",
      }),
    }),

    getCommunityById: builder.query<
      CommonResponseType & { data: any },
      { communityId: string | undefined }
    >({
      query: ({ communityId }) => ({
        url: `${END_POINTS.community}/${communityId}`,
        method: "GET",
      }),
    }),

    updateCommunity: builder.mutation<
      CommonResponseType,
      UpdateCommunityParams
    >({
      query: ({ body, communityId }) => ({
        url: `${END_POINTS.community}/${communityId}`,
        method: "PUT",
        body,
      }),
    }),

    getAllPosts: builder.query<
      CommonResponseType & { data: any },
      { communityId: string | undefined }
    >({
      query: ({ communityId }) => ({
        url: `${END_POINTS.getAllPost}?community=${communityId}`,
        method: "GET",
      }),
    }),

    getPostById: builder.query<
      CommonResponseType & { data: any },
      { postId: string | undefined }
    >({
      query: ({ postId }) => ({
        url: `${END_POINTS.getAllPost}/${postId}`,
        method: "GET",
      }),
    }),

    getAllComments: builder.query<
      CommonResponseType & { data: any },
      { postId: string | undefined }
    >({
      query: ({ postId }) => ({
        url: `${END_POINTS.comment}?postId=${postId}`,
        method: "GET",
      }),
    }),

    deletePost: builder.mutation<CommonResponseType, { postId: string }>({
      query: ({ postId }) => ({
        url: `${END_POINTS.post}/${postId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetAllCommunityQuery,
  useAddCommunityMutation,
  useDeleteCommunityMutation,
  useLazyGetCommunityByIdQuery,
  useUpdateCommunityMutation,
  useLazyGetAllPostsQuery,
  useLazyGetPostByIdQuery,
  useLazyGetAllCommentsQuery,
  useDeletePostMutation,
} = subscriptionApi;
