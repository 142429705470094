import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Checkbox,
} from "@mui/material";
import {
  useLazyGetAllUsersQuery,
  useChangeUserStatusMutation,
  useDeleteUserMutation,
  useLazyGetUserCsvQuery,
} from "../../services/users";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import { AllUser } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import { API_URL } from "../../constants/url";
import useAuth from "../../hooks/useAuth";

const ManageUsers = () => {
  const navigate = useNavigate();
  const [getAllUserData, { isLoading }] = useLazyGetAllUsersQuery();
  const [userStatus] = useChangeUserStatusMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [userCsv] = useLazyGetUserCsvQuery();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allUsers, setAllUsers] = useState<AllUser[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Users"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const getUsers = async () => {
    try {
      const response = await getAllUserData({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllUsers(response?.data?.data || []);
        setTotalCount(response?.data?.totalPages);
      } else {
        setAllUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleExportCsv = async () => {
    window.open(`${API_URL}export/user`);
  };

  function createData(
    name: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string
  ) {
    return { name, phone, email, planName, planPrice };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Gurinder Singh",
      "9876543210",
      "gurinder@apptunix.com",
      "Free",
      "$0,00"
    ),
  ];

  const updateStatus = async (id: string, isBlocked: boolean | undefined) => {
    const body = {
      isBlocked: isBlocked == true ? false : true,
    };
    try {
      const response = await userStatus({ userId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        getUsers();
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteUser({ userId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("User deleted successfully");
        getUsers();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    getUsers();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Users</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Plan Name</TableCell>
                  <TableCell>Plan Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsers?.length > 0 ? (
                  allUsers.map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell align="center">
                        <figure className="user_img">
                          <img
                            src={
                              row?.image
                                ? row?.image
                                : "/static/images/user_placeholder.png"
                            }
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{row.name || "-"}</TableCell>
                      <TableCell>{`${row.countryCode || ""} ${
                        row?.phone || "-"
                      }`}</TableCell>
                      <TableCell>{row.email || "-"}</TableCell>
                      <TableCell>
                        {row?.usersubscriptions?.productId ==
                        "subscription_monthly"
                          ? "Plus Plan"
                          : row?.usersubscriptions?.productId ==
                            "subscription_annual"
                          ? "Premium Plan"
                          : "Basic Plan"}
                      </TableCell>
                      <TableCell>{"-"}</TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          size="small"
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(row?._id, row?.isBlocked)
                          }
                        />
                      </TableCell>
                      
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-users/detail/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
{/*                          
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-users/detail/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton> */}
                           
                           {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-users/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                            ) : (
                              <></>
                            )}
                          {/* <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton> */}
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isDelete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                               ) : (
                                <></>
                              )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No User Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={allUsers}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalCount}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="User"
      />
    </MainContainer>
  );
};

export default ManageUsers;
