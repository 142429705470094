import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  useLazyGetAllSubscriptionsQuery,
  useChangeSubscriptionStatusMutation,
  useDeleteSubscriptionMutation,
} from "../../services/subscription";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isValidInput } from "../../utils/validations";
import { Subscription } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";

const ManageSubscriptions = () => {
  const navigate = useNavigate();
  const [getSubscriptionsData, { isLoading }] =
    useLazyGetAllSubscriptionsQuery();
  const [subscriptionStatus] = useChangeSubscriptionStatusMutation();
  const [deleteSubscription] = useDeleteSubscriptionMutation();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [subsData, setSubsData] = useState<Subscription[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Subscription"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const getSubscriptions = async () => {
    try {
      const response = await getSubscriptionsData({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubsData(response?.data?.data);
        setTotalCount(response?.data?.totalPages);
      } else {
        setSubsData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, isBlocked: boolean | undefined) => {
    const body = {
      isBlocked: isBlocked == true ? false : true,
    };
    try {
      const response = await subscriptionStatus({ subsId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        getSubscriptions();
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubscription({ subsId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Subscription deleted successfully");
        getSubscriptions();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  function createData(
    Name: string,
    Description: string,
    Monthly: string,
    Quarterly: string,
    Yearly: string
  ) {
    return { Name, Description, Monthly, Quarterly, Yearly };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Free Plan", "Lorem ipsum dolor...", "$0.00", "$0.00", "$0.00"),
    createData(
      "Premium Plan",
      "Lorem ipsum dolor...",
      "$14.00",
      "$50.00",
      "$120.00"
    ),
  ];

  useEffect(() => {
    getSubscriptions();
  }, [page, debouncedSearchTerm]);
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Subscriptions</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
               <Box className="cards_header_right">
            {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subscriptions/add")}
              >
                Add Subscription
              </Button>
               ) : (
                <></>
              )}
            </Box>
            {/* <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subscriptions/add")}
              >
                Add Subscription
              </Button>
            </Box> */}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>

                  <TableCell>Name</TableCell>
                  {/* <TableCell>Description</TableCell> */}
                  <TableCell>Price</TableCell>

                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subsData?.length ? (
                  subsData.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>

                      <TableCell>{row?.name || "-"}</TableCell>
                      {/* <TableCell>{row?.description || "-"}</TableCell> */}
                      <TableCell>{row?.price || "-"}</TableCell>

                      <TableCell>
                        <Switch
                          {...label}
                          size="small"
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(row?._id, row?.isBlocked)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/manage-subscriptions/detail/${row?._id}`
                              )
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {/* {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-sub-admin/edit/${item?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                             ) : (
                              <></>
                            )} */}
                             {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-subscriptions/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                             ) : (
                              <></>
                            )}
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isDelete ? (
<IconButton onClick={() => {
  setSelectedId(row?._id);
  setOpen(true);
}}>
  <DeleteIcon />
</IconButton>
 ) : (
  <></>
)}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No Subscription Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={subsData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalCount}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Subscription"
      />
    </MainContainer>
  );
};

export default ManageSubscriptions;
