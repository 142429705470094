import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageUsers from "./users";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageAllergies from "./manageAllergies";
import AddAllergy from "./manageAllergies/add";
import ManageRevenue from "./manageRevenue";
import ManageSubscriptions from "./manageSubscriptions";
import AddSubscription from "./manageSubscriptions/add";
import EditSubscription from "./manageSubscriptions/edit";
import RecievedNotifications from "./manageNotification/receivedNotification";
import UserEdit from "./users/userEdit";
import UserDetail from "./users/userDetail";
import ChangePassword from "./profile/changePassword";
import SubscriptionDetails from "./manageSubscriptions/view";
import EditAllergy from "./manageAllergies/edit";
import ManageCommunity from "./manageCommunity";
import AddCommunity from "./manageCommunity/add";
import EditCommunity from "./manageCommunity/edit";
import CommunityDetails from "./manageCommunity/view";
import PostDetails from "./manageCommunity/postDetails";
import ManageProducts from "./manageProducts";
import AddProducts from "./manageProducts/add";
import ProductDetails from "./manageProducts/details";
import AddSubAdmin from "./ManageSubAdmin/add";
import ManageSubAdmin from "./ManageSubAdmin";
import AddCategory from "./manageCategories/add";
import CategoryDetails from "./manageCategories/details";
import ManageCategory from "./manageCategories";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ForgotPasswordPage,
  VerifyOtp,
  Faq,
  ResetPassword,
  Profile,
  ChangePassword,
  ManageUsers,
  UserEdit,
  UserDetail,
  ManageCms,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageAllergies,
  AddAllergy,
  EditAllergy,
  ManageRevenue,
  ManageSubscriptions,
  AddSubscription,
  EditSubscription,
  SubscriptionDetails,
  RecievedNotifications,
  ManageCommunity,
  AddCommunity,
  EditCommunity,
  CommunityDetails,
  PostDetails,
  ManageProducts,
  AddProducts,
  ProductDetails,
  AddSubAdmin,
  ManageSubAdmin,
  AddCategory,
  CategoryDetails,
  ManageCategory
};
