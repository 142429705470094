import { Routes, Route } from "react-router-dom";
import Pages from "./pages";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/change-password" element={<Pages.ChangePassword />} />
      <Route path="/manage-users" element={<Pages.ManageUsers />} />
      <Route path="/manage-users/detail/:id" element={<Pages.UserDetail />} />
      <Route path="/manage-users/edit/:id" element={<Pages.UserEdit />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route path="/manage-allergies" element={<Pages.ManageAllergies />} />
      <Route path="/manage-allergies/add" element={<Pages.AddAllergy />} />
      <Route
        path="/manage-allergies/edit/:id"
        element={<Pages.EditAllergy />}
      />
 <Route path="/manage-product" element={<Pages.ManageProducts />} />
 <Route path="/manage-product/add" element={<Pages.AddProducts />} />
 <Route path="/manage-product/edit/:id" element={<Pages.AddProducts />} />
 <Route path="/manage-product/details/:id" element={<Pages.ProductDetails />} />
 <Route path="/manage-category" element={<Pages.ManageCategory />} />
 <Route path="/manage-category/add" element={<Pages.AddCategory />} />
 <Route path="/manage-category/edit/:id" element={<Pages.AddCategory />} />
 <Route path="/manage-category/details/:id" element={<Pages.CategoryDetails />} />

      <Route path="/manage-revenue" element={<Pages.ManageRevenue />} />
      <Route
        path="/manage-subscriptions"
        element={<Pages.ManageSubscriptions />}
      />
      <Route
        path="/manage-subscriptions/add"
        element={<Pages.AddSubscription />}
      />
      <Route
        path="/manage-subscriptions/edit/:id"
        element={<Pages.EditSubscription />}
      />
      <Route
        path="/manage-subscriptions/detail/:id"
        element={<Pages.SubscriptionDetails />}
      />
      <Route path="/manage-community" element={<Pages.ManageCommunity />} />
      <Route path="/manage-community/add" element={<Pages.AddCommunity />} />
      <Route
        path="/manage-community/edit/:id"
        element={<Pages.EditCommunity />}
      />
      <Route
        path="/manage-community/details/:id"
        element={<Pages.CommunityDetails />}
      />
      <Route
        path="/manage-community/post-details/:id"
        element={<Pages.PostDetails />}
      />
       <Route
        path="/manage-sub-admin"
        element={<Pages.ManageSubAdmin />}
      />
       <Route
        path="/manage-sub-admin/add"
        element={<Pages.AddSubAdmin />}
      />
        <Route
        path="/manage-sub-admin/edit/:id"
        element={<Pages.AddSubAdmin />}
      />
    </Routes>
  );
};

export default Routing;
