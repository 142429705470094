 
import { PropsWithoutRef, useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
 import CardMembershipIcon from "@mui/icons-material/CardMembership";
import CategoryIcon from '@mui/icons-material/Category';
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleIcon from "@mui/icons-material/People";
import { SIDEBAR_WIDTH } from "../constants";
import GroupsIcon from "@mui/icons-material/Groups";
import useAuth from "../hooks/useAuth";
 
 import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    
    const hasDashboardPermission = userData?.permissions?.some((permission: { name: string; isView: any; }) => permission.name === "Dashboard" && permission.isView);
    if (hasDashboardPermission) {
      navigate("/dashboard");
    }
  };

  const userData = useAuth();
  const items = [
    {
      id: 1,
      name: "Dashboard",
      icon:  <DashboardIcon />,
      route: "/dashboard",
    },
    {
      id: 2,
      name: "Allergies",
      icon:  <ListAltIcon />,
      route: "/manage-allergies",
    },
    {
      id: 3,
      name: "Products",
      icon: <CategoryIcon />,
      route: "/manage-product",
    },
    {
      id: 4,
      name: "Category",
      icon: <ReceiptIcon />,
      route: "/manage-category",
    },

    {
      id: 5,
      name: "Users",
      icon:    <PeopleIcon /> ,
      route: "/manage-users",
    },
    
    {
      id: 6,
      name: "Subscriptions",
      icon:  <CardMembershipIcon />,
      route: "/manage-subscriptions",
    },
    {
      id: 7,
      name: "Community",
      icon: <GroupsIcon />,
      route: "/manage-community",
    },
    {
      id: 8,
      name: "Sub-Admin",
      icon: <GroupsIcon />,
      route: "/manage-sub-admin",
    },
    {
      id: 9,
      name: "Notifications",
      icon: <NotificationsActiveIcon />,
      route: "/manage-notifications",
    },
    {
      id: 10,
      name: "Customer Support",
      icon: <LiveHelpIcon />,
      route: "/customer-support",
    },
    
    {
      id: 11,
      name: "CMS",
      icon: <SummarizeTwoToneIcon />,
      route: "/cms",
    },
    
    
  ];

  // const [list, setList] = useState<any>(
  //   userData?.role === "ADMIN" ? items : []
  // );

  const [list, setList] = useState<any>(
    userData?.role === "ADMIN" ? items : userData?.permissions
  );

  console.log(list, "-0-0-0");

  useEffect(() => {
    let arr = [];
    if (userData?.role === "ADMIN") {
      setList(items);
    } else if (userData?.permissions?.length) {
      const permissions = userData?.permissions;
      arr = items.map((item) => {
        let idx = -1;
        idx = permissions?.findIndex((ele: any) => ele?.name === item?.name);
        if (idx > -1) {
          if (permissions[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [userData]);

  // const drawer = (
  //   <>
  //     <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
  //       <figure>
  //         <img
  //           src="/static/images/logo.png"
  //           alt=""
  //           onClick={() => navigate("/dashboard")}
  //         />
  //       </figure>
  //       <IconButton
  //         sx={{
  //           position: "absolute",
  //           top: "10px",
  //           right: "10px",
  //           color: "white",
  //           display: { lg: "none" },
  //         }}
  //         onClick={handleSidebarToggle}
  //       >
  //         <CloseIcon />
  //       </IconButton>
  //     </Toolbar>

  //     <List sx={{ flexGrow: 1 }} className="sidebr-lst">
  //       <ListItemButton
  //         className="lst-itm"
  //         selected={location.pathname === "/dashboard"}
  //         onClick={() => {
  //           // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //           navigate("/dashboard");
  //         }}
  //       >
  //         <>
  //           <ListItemIcon>
  //             <DashboardIcon />
  //           </ListItemIcon>
  //           <ListItemText className="lstitm-txt" primary="Dashboard" />
  //         </>
  //       </ListItemButton>
  //       {list?.length
  //         ? list?.map((item: any) => (
  //             <ListItemButton
  //               key={item?.id}
  //               className="lst-itm"
  //               selected={location.pathname.includes(item?.route)}
  //               onClick={() => {
  //                 // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //                 // item?.id !== 4 &&
  //                 navigate(item?.route)
                     
  //               }}
  //             >
  //               {item?.icon ? (
  //                 <>
  //                   <ListItemIcon>{item?.icon}</ListItemIcon>
  //                   <ListItemText className="lstitm-txt" primary={item?.name} />
  //                 </>
  //               ) : undefined}
  //             </ListItemButton>
  //           ))
  //         : undefined}
  //     </List>
  //   </>
  // );

  // useEffect(() => {
  //   if (userData?.role === "ADMIN") {
  //     setList(items);
  //   } else {
  //     let updatedItems = [...items];

  //     if (userData && userData.subAdminId) {
  //       const labels = {
  //         trainer: "Manage Trainers",
  //         category: "Manage Categories",
  //         cms: "Manage CMS",
  //         trainingMaterial: "Manage Training Material",
  //         agent: "Manage Agents",
  //       };

  //       const data = Object.keys(labels).map((name) => {
  //         return {
  //           _id: userData.subAdminId._id,
  //           // @ts-ignore
  //           label: labels[name],
  //           isEdit: userData.subAdminId[`${name}Add`] || false,
  //           isView: userData.subAdminId[`${name}View`] || false,
  //           isDelete: userData.subAdminId[`${name}Delete`] || false,
  //           disabled: false,
  //           name: name,
  //         };
  //       });

  //       updatedItems = updatedItems.filter((item) => {
  //         const dataItem = data.find(
  //           (dataItem) => dataItem.label === item.name
  //         );

  //         if (dataItem && dataItem.isView) {
  //           return true;
  //         }

  //         return false;
  //       });
  //     }

  //     setList(updatedItems);
  //   }
  // }, [userData]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
    <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
            right: 0,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={handleNavigate}
            />
          </figure>

          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.route);
                return (
                  <List key={item?.name} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.route)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
            right: 0,
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={handleNavigate}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.route);
                return (
                  <List key={item?.name} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      // className="lst-itm"
                      // selected={
                      //   location.pathname.includes(item.selected) === item.selected
                      // }
                      onClick={() => navigate(item?.route)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
        {/* {drawer} */}
      </Drawer>






    </Box>
  );
}
