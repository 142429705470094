import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  useLazyGetCommunityByIdQuery,
  useLazyGetAllPostsQuery,
  useDeletePostMutation,
} from "../../services/community";
import { showError, showToast } from "../../constants";
import moment from "moment";
import WarnModal from "../../components/WarnModal";

const CommunityDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [communityDetails] = useLazyGetCommunityByIdQuery();
  const [getAllPostsQuery, { isLoading }] = useLazyGetAllPostsQuery();
  const [deletePost] = useDeletePostMutation();
  const [communityData, setCommunityData] = useState<any>();
  const [postData, setPostData] = useState<any>();
  const [communityId, setCommunityId] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const getSubsDetailById = async (id: string | undefined) => {
    try {
      const response = await communityDetails({ communityId: id }).unwrap();
      if (response?.statusCode === 200) {
        setCommunityData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deletePost({ postId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Post deleted successfully");
        getAllPosts();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getAllPosts = async () => {
    try {
      const response = await getAllPostsQuery({
        communityId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setPostData(response?.data?.post);
        // setTotalCount(response?.data?.totalPages);
        setCommunityId(response?.data?.post[0]?.noOfUsersInCommunity);
      } else {
        setCommunityData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(
    Name: string,
    Description: string,
    Monthly: string,
    Quarterly: string,
    Yearly: string
  ) {
    return { Name, Description, Monthly, Quarterly, Yearly };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "new allergy",
      "Lorem ipsum dolor...",
      "$0.00",
      "$0.00",
      "$0.00"
    ),
    createData(
      "new allergy",
      "Lorem ipsum dolor...",
      "$14.00",
      "$50.00",
      "$120.00"
    ),
  ];

  useEffect(() => {
    getSubsDetailById(id);
  }, []);

  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Community Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-community");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12}>
                <figure className="profile_img">
                  <img
                    src={
                      communityData?.image
                        ? communityData?.image
                        : "/static/images/image_placeholder.jpg"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">
                        {communityData?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">
                        People in community
                      </Typography>
                      <Typography component="p">
                        {communityId || "-"}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <h3 style={{ paddingTop: 5 }}>Posts</h3>

            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>

                    <TableCell>Title</TableCell>
                    {/* <TableCell>Description</TableCell> */}
                    <TableCell>Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postData?.length > 0
                    ? postData?.map((row: any, i: any) => (
                        <TableRow>
                          <TableCell align="center">{i + 1}</TableCell>

                          <TableCell
                            style={{
                              whiteSpace: "pre-wrap",
                              overflow: "hidden",
                            }}
                          >
                            {row?.title || "-"}
                          </TableCell>
                          {/* <TableCell
                            style={{
                              whiteSpace: "pre-wrap",
                              overflow: "hidden",
                            }}
                          >
                            {row?.description || "-"}
                          </TableCell> */}
                          <TableCell>
                            {" "}
                            {moment(row?.createdAt).format("MM/D/YYYY")}
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-community/post-details/${row?._id}`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Post"
      />
    </MainContainer>
  );
};

export default CommunityDetails;
