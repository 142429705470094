import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Switch,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useAddSubscriptionMutation } from "../../services/subscription";
import RemoveIcon from "@mui/icons-material/Remove";
import { showError, showToast } from "../../constants";
import { isNumber } from "../../utils/validations";
import { UploadMedia } from "../../utils/mediaUpload";
import useAuth from "../../hooks/useAuth";

interface PlanFeature {
  id: number;
  name: string;
  enable: boolean;
  isCount: boolean;
  isUnlimited: boolean;
  type: number;
  toggle: boolean;
}

const AddSubscription = () => {
  const navigate = useNavigate();
  const [addSubscriptionMutation, { }] = useAddSubscriptionMutation();

  const [image, setImage] = useState<string>("");
  const [error, setError] = useState(false);
  const [features, setFeatures] = useState<any[]>([{ feature: "" }]);
  const [featureError, setFeatureError] = useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [count, setCount] = useState("");
  const [scans, setScans] = useState(false);
  const [history, setHistory] = useState(false);
  const [community, setCommunity] = useState(false);
  const [count1, setCount1] = useState("");
  const [count2, setCount2] = useState("");
  const [count3, setCount3] = useState("");
  const [unlimited, setUnlimited] = useState(false);
  const [unlimited1, setUnlimited1] = useState(false);
  const [unlimited2, setUnlimite2] = useState(false);
  const [enable, setEnable] = useState(false);
  const [enable1, setEnabl1] = useState(false);
  const [enable2, setEnable2] = useState(false);
  const [enable3, setEnable3] = useState(false);
  const [enable4, setEnable4] = useState(false);
  const [enable5, setEnable5] = useState(false);
  const [enable6, setEnable6] = useState(false);
  const [planType, setPlanType] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });

  console.log("userdata", userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  console.log(hidePermission, "hideeeeee");

  useEffect(() => {
    checkPermission();
  }, [userData]);
  const abb = [
    {
      id: 1,
      name: "scans/month",
      count: Number(count1),
      isUnlimited: unlimited,
      enable: enable,
      type: 1,
    },
    {
      id: 2,
      name: "history saves/month",
      count: Number(count2),
      isUnlimited: unlimited1,
      enable: enable1,
      type: 2,
    },
    {
      id: 3,
      name: "allergy communities",
      count: Number(count3),
      isUnlimited: unlimited2,
      enable: enable2,
      type: 3,
    },
    {
      id: 4,
      name: "Patient profile alerts",
      enable: enable3,
      isCount: false,
      toggle: false,
      type: 4,
    },
    {
      id: 5,
      name: "Share care plan",
      enable: enable4,
      type: 5,
    },
    {
      id: 6,
      name: "Alert saved contacts",
      enable: enable5,
      type: 11,
    },
    {
      id: 7,
      name: "Medication Expiry Alert",
      enable: enable6,
      type: 12,
    },
  ];

  console.log(abb);

  const handleChange = () => {
    setUnlimited(!unlimited);
  };
  const handleChange1 = () => {
    setUnlimited1(!unlimited1);
  };
  const handleChange2 = () => {
    setUnlimite2(!unlimited2);
  };

  const handleEnable = () => {
    setEnable(!enable);
  };
  const handleEnable1 = () => {
    setEnabl1(!enable1);
  };
  const handleEnable2 = () => {
    setEnable2(!enable2);
  };
  const handleEnable3 = () => {
    setEnable3(!enable3);
  };
  const handleEnable4 = () => {
    setEnable4(!enable4);
  };
  const handleEnable5 = () => {
    setEnable5(!enable5);
  };
  const handleEnable6 = () => {
    setEnable6(!enable6);
  };

  const [selectedFeatures, setSelectedFeatures] = useState<PlanFeature[]>([]);
  // console.log(selectedFeatures);

  // const handleStatusToggle = (feature: PlanFeature) => {
  //   if (feature.enable) {
  //     if (selectedFeatures.some((f) => f.id === feature.id)) {
  //       setSelectedFeatures((prevSelectedFeatures) =>
  //         prevSelectedFeatures.filter((f) => f.id !== feature.id)
  //       );
  //     } else {
  //       setSelectedFeatures((prevSelectedFeatures) => [
  //         ...prevSelectedFeatures,
  //         {
  //           ...feature,
  //           count: Number(count),
  //           isCount:
  //             feature?.id === 1
  //               ? scans
  //               : feature?.id === 2
  //               ? history
  //               : community,
  //         },
  //       ]);
  //     }
  //   }
  // };

  const handleStatusToggle = (feature: PlanFeature) => {
    if (feature.enable) {
      const newCount =
        feature.id === 1 ? scans : feature.id === 2 ? history : community;

      if (feature.id === 1) {
        setSelectedFeatures((prevSelectedFeatures) => [
          ...prevSelectedFeatures.filter((f) => f.id !== 1),
          {
            ...feature,
            count: Number(newCount),
            isCount: feature.isCount,
          },
        ]);
      } else {
        setSelectedFeatures((prevSelectedFeatures) => [
          ...prevSelectedFeatures,
          {
            ...feature,
            count: Number(newCount),
            isCount: feature.isCount,
          },
        ]);
      }
    }
  };

  const handleToggleUnlimited = (item: any) => {
    let indexToDelete = planFeatures?.indexOf(item);
    let newArray = planFeatures
      .slice(0, indexToDelete)
      .concat(planFeatures.slice(indexToDelete + 1));
    setPlanFeatures([...newArray]);
    if (item?.isUnlimited === false) {
      setPlanFeatures((prevSelectedFeatures) => [
        ...prevSelectedFeatures,
        {
          ...item,
          isUnlimited: true,
        },
      ]);
    } else {
      setPlanFeatures((prevSelectedFeatures) => [
        ...prevSelectedFeatures,
        {
          ...item,
        },
      ]);
    }
  };

  const [planFeatures, setPlanFeatures] = useState<PlanFeature[]>([
    {
      id: 1,
      name: "scans/month",
      enable: true,
      isCount: true,
      isUnlimited: false,
      toggle: true,
      type: 1,
    },
    {
      id: 2,
      name: "history saves/month",
      enable: true,
      isCount: true,
      isUnlimited: false,
      toggle: true,
      type: 2,
    },
    {
      id: 3,
      name: "allergy communities",
      enable: true,
      isCount: true,
      isUnlimited: false,
      toggle: true,
      type: 3,
    },
    {
      id: 4,
      name: "Patient profile alerts",
      enable: true,
      isCount: false,
      isUnlimited: false,
      toggle: false,
      type: 4,
    },
    {
      id: 5,
      name: "Share care plan",
      enable: true,
      isCount: false,
      isUnlimited: false,
      toggle: false,
      type: 5,
    },
    {
      id: 6,
      name: "Alert saved contacts",
      enable: true,
      isCount: false,
      isUnlimited: false,
      toggle: false,
      type: 11,
    },
  ]);

  const sortedFeatures = [...planFeatures].sort((a, b) => {
    // Place the item with row?.id === 1 at the top
    if (a?.id === 1) {
      return -1;
    } else if (b?.id === 1) {
      return 1;
    }

    // Sort the rest of the items based on their IDs
    return a.id - b.id;
  });

  // console.log(planFeatures);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      monthlyPrice: "",
      quarterlyPrice: "",
      yearlyPrice: "",
      productId: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      productId: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 2 characters are required"),
      monthlyPrice: Yup.string().required("This field is required"),
      // .min(2, "Minimum 2 digits are required"),
      quarterlyPrice: Yup.string().required("This field is required"),
      yearlyPrice: Yup.string().required("This field is required"),
    }),
    onSubmit: async () => {
      const nonEmptyFeatures = features.filter(
        (feature) => feature.feature.trim() !== ""
      );

      if (features[0]?.feature == "") {
        setFeatureError(true);
      } else {
        setFeatureError(false);
      }
      formik.setSubmitting(true);

      const body = {
        name: formik?.values?.name,
        description: formik?.values?.description,
        price: formik?.values?.yearlyPrice,
        features: abb,
        productId: formik.values.productId,
        planType: Number(planType),
        subscriptionType: Number(subscriptionType),
      };

      if (!image) {
        setError(true);
        return;
      }

      try {
        const response = await addSubscriptionMutation(body).unwrap();
        if (response?.statusCode === 200) {
          showToast("Subscription Added Successfully");
          formik.resetForm();
          navigate("/manage-subscriptions");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handlePlanChange = (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string);
  };

  const handleSubsChange = (event: SelectChangeEvent) => {
    setSubscriptionType(event.target.value as string);
  };

  const handleAddFeature = () => {
    const newFeatures = [...features, { feature: "" }];
    setFeatures(newFeatures);
  };

  const handleRemoveFeature = (index: number) => {
    const updatedFeatures = features.filter((_, i) => i !== index);
    setFeatures(updatedFeatures);
  };

  const handleFeatureChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const updatedFeatures = features.map((feature, i) => {
      if (i === index) {
        return { ...feature, feature: event.target.value };
      }
      return feature;
    });
    setFeatures(updatedFeatures);
  };

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Subscription</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subscriptions");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Subscription Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                    ></TextField>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className="custom_label">
                      Subscription Description
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="description"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Description"
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    ></TextField>
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Typography className="custom_label">
                      Monthly Price
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="monthlyPrice"
                      variant="outlined"
                      fullWidth
                      placeholder="Monthly Price"
                      id="monthlyPrice"
                      onBlur={formik.handleBlur}
                      value={formik.values.monthlyPrice}
                      onChange={(val) => {
                        const inputValue = val.target.value;

                        // Check if the input is a valid number with up to 2 decimals
                        const isValidInput = /^(\d{1,10}(\.\d{0,2})?)?$/.test(
                          inputValue
                        );

                        if (isValidInput) {
                          // Update the form field only if the input is valid
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.monthlyPrice &&
                        formik.errors.monthlyPrice
                      }
                    />
                  </Grid> */}
                  {/* <Grid item xs={4}>
                    <Typography className="custom_label">
                      Quarterly Price
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="quarterlyPrice"
                      className="text_field"
                      id="quarterlyPrice"
                      variant="outlined"
                      fullWidth
                      placeholder="Quarterly Price"
                      onBlur={formik.handleBlur}
                      value={formik.values.quarterlyPrice}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.quarterlyPrice &&
                        formik.errors.quarterlyPrice
                      }
                    ></TextField>
                  </Grid> */}
                  <Grid item xs={4}>
                    <Typography className="custom_label">Price</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="yearlyPrice"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Yearly Price"
                      onBlur={formik.handleBlur}
                      value={formik.values.yearlyPrice}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.yearlyPrice && formik.errors.yearlyPrice
                      }
                    ></TextField>
                  </Grid>

                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">Plan Type</Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        className="select_div2"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={planType}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handlePlanChange}
                      >
                        <MenuItem value="" disabled>
                          Select Plan Type
                        </MenuItem>
                        <MenuItem value="1">Monthly</MenuItem>
                        <MenuItem value="2">Quarterly</MenuItem>
                        <MenuItem value="3">Half Yearly</MenuItem>
                        <MenuItem value="4">Yearly</MenuItem>
                      </Select>
                      {error && !planType ? (
                        <h6 className="err_msg">Please select Plan Type</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Subscription Type
                    </Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        className="select_div2"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subscriptionType}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handlePlanChange}
                      >
                        <MenuItem value="" disabled>
                          Select Subscription Type
                        </MenuItem>
                        <MenuItem value="1">Free</MenuItem>
                        <MenuItem value="2">Paid</MenuItem>
                      </Select>
                      {error && !planType ? (
                        <h6 className="err_msg">Please select Plan Type</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Subscription Id
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="productId"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Subscription Id"
                      onBlur={formik.handleBlur}
                      value={formik.values.productId}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.productId && formik.errors.productId
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className="custom_title"
                      component="h5"
                      sx={{ pb: 2 }}
                    >
                      Features
                    </Typography>
                    {/* <Grid item xs={12}>
                      <TableContainer
                        className="table_container"
                        sx={{ margin: 0 }}
                      >
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">S.No</TableCell>
                              <TableCell>Feature</TableCell>
                              <TableCell>Count</TableCell>
                              <TableCell>Unlimited</TableCell>
                              <TableCell> Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortedFeatures?.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell align="center">{i + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell sx={{ width: 200 }}>
                                  {row?.isCount ? (
                                    <TextField
                                      hiddenLabel
                                      type={"text"}
                                      name="yearlyPrice"
                                      className="text_field"
                                      variant="outlined"
                                      fullWidth
                                      placeholder="Count"
                                      onChange={(val) =>
                                        setCount(val.target.value)
                                      }
                                    ></TextField>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {row?.toggle ? (
                                    <Switch
                                      {...label}
                                      defaultChecked={row?.isUnlimited}
                                      onChange={() =>
                                        handleToggleUnlimited(row)
                                      }
                                      size="small"
                                      // value={true}
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Switch
                                    {...label}
                                    checked={selectedFeatures.some(
                                      (f) => f.id === row.id
                                    )}
                                    size="small"
                                    onChange={() => handleStatusToggle(row)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">1.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              scans/month
                            </Typography>
                          </Box>
                          <TextField
                            sx={{ width: "20%" }}
                            hiddenLabel
                            type={"text"}
                            name="yearlyPrice"
                            className="text_field"
                            variant="outlined"
                            fullWidth
                            placeholder="count"
                            value={count1}
                            onChange={(val) => {
                              const inputVal = val.target.value;

                              // Use a regular expression to check if the input is a valid number
                              if (/^\d*$/.test(inputVal)) {
                                setCount1(inputVal);
                              }
                            }}
                          // helperText={
                          //   formik.touched.yearlyPrice &&
                          //   formik.errors.yearlyPrice
                          // }
                          ></TextField>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Unlimited :
                            </Typography>
                            <Switch
                              {...label}
                              checked={unlimited}
                              onChange={handleChange}
                              size="small"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable}
                              onChange={handleEnable}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">2.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              history saves / month
                            </Typography>
                          </Box>
                          <TextField
                            sx={{ width: "20%" }}
                            hiddenLabel
                            type={"text"}
                            name="yearlyPrice"
                            className="text_field"
                            variant="outlined"
                            fullWidth
                            placeholder="count"
                            value={count2}
                            onChange={(val) => {
                              const inputVal = val.target.value;

                              // Use a regular expression to check if the input is a valid number
                              if (/^\d*$/.test(inputVal)) {
                                setCount2(inputVal);
                              }
                            }}
                          // helperText={
                          //   formik.touched.yearlyPrice &&
                          //   formik.errors.yearlyPrice
                          // }
                          ></TextField>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Unlimited :
                            </Typography>
                            <Switch
                              {...label}
                              checked={unlimited1}
                              onChange={handleChange1}
                              size="small"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable1}
                              onChange={handleEnable1}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">3.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              allergy communities
                            </Typography>
                          </Box>
                          <TextField
                            sx={{ width: "20%" }}
                            hiddenLabel
                            type={"text"}
                            name="yearlyPrice"
                            className="text_field"
                            variant="outlined"
                            fullWidth
                            placeholder="count"
                            value={count3}
                            onChange={(val) => {
                              const inputVal = val.target.value;

                              // Use a regular expression to check if the input is a valid number
                              if (/^\d*$/.test(inputVal)) {
                                setCount3(inputVal);
                              }
                            }}
                          // helperText={
                          //   formik.touched.yearlyPrice &&
                          //   formik.errors.yearlyPrice
                          // }
                          ></TextField>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Unlimited :
                            </Typography>
                            <Switch
                              {...label}
                              checked={unlimited2}
                              onChange={handleChange2}
                              size="small"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable2}
                              onChange={handleEnable2}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">4.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              Patient profile alerts
                            </Typography>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable3}
                              onChange={handleEnable3}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">5.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              Share care plan
                            </Typography>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable4}
                              onChange={handleEnable4}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">6.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              Alert saved contacts
                            </Typography>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable5}
                              onChange={handleEnable5}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">7.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              Medication Expiry Alert
                            </Typography>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable6}
                              onChange={handleEnable6}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    {/* <Grid
                      container
                      direction="column"
                      alignItems="start"
                      rowSpacing={1}
                      columnSpacing={2}
                    >
                      {features.map((feature, index) => (
                        <Grid item key={index} sx={{ width: "50%" }}>
                          <div className="feature_input">
                            <TextField
                              hiddenLabel
                              type="text"
                              name="features"
                              id="features"
                              variant="outlined"
                              fullWidth
                              placeholder={`Feature ${index + 1}`}
                              value={feature?.feature}
                              onChange={(event) =>
                                handleFeatureChange(event, index)
                              }
                            />
                            {index === features.length - 1 ? (
                              <Button
                                disabled={!feature?.feature}
                                className="btn"
                                onClick={handleAddFeature}
                              >
                                <AddIcon />
                              </Button>
                            ) : (
                              <Button
                                className="btn"
                                onClick={() => handleRemoveFeature(index)}
                              >
                                <RemoveIcon />
                              </Button>
                            )}
                          </div>
                          <div>
                            <p> {featureError ? "error" : ""}</p>
                          </div>
                        </Grid>
                      ))}
                    </Grid> */}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubscription;
