import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import { Loader, showError } from "../../constants";
import { useLazyGetProductByIdQuery } from "../../services/product";
import Modal from "@mui/material/Modal";
import useAuth from "../../hooks/useAuth";
import { format } from "date-fns";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProductDetails = () => {
  const navigate = useNavigate();
  const UserPlanFeatures = [
    { id: 1, name: "3 times per day" },
    // { id: 2, name: "Genetic Testing" },
    // { id: 3, name: "Allergy Monitoring" },
  ];
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const { id } = useParams();
  const [getProductData, { isLoading }] = useLazyGetProductByIdQuery();
  const [productDetails1, setProductDetails] = useState<any>();
  const [allergies, setAllergies] = useState<any[]>([]);

  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });

  console.log("userdata", userData);
  const parseHTML = (htmlString: string | undefined) => {
    if (!htmlString) return "";

    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Products");
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  console.log(hidePermission, "hideeeeee");

  useEffect(() => {
    checkPermission();
  }, [userData]);

  const getProductDetailById = async (id: string | undefined) => {
    try {
      const response = await getProductData({ subId: id }).unwrap();
      if (response?.statusCode === 200) {
        setProductDetails(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  const formatDateTime = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = format(new Date(dateString), "yyyy-MM-dd");
    const time = format(new Date(dateString), "HH:mm:ss");
    return ` ${date}, Time: ${time}`;
  };
  useEffect(() => {
    getProductDetailById(id);
  }, []);
  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Product Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-product");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid item lg={2} md={2} sm={6} xs={12}>
                  <Typography component="h5" style={{ fontWeight: "bold" }}>
                    Image
                  </Typography>
                  <div
                    className="profile_img"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {productDetails1?.uploads.map(
                      (upload: any, index: number) => (
                        <img
                          key={index}
                          style={{
                            borderRadius: 0,
                            marginBottom: "5px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          src={upload?.link}
                          alt={`Image ${index + 1}`}
                          onClick={() => handleImageClick(upload.link)}
                        />
                      )
                    )}

                    {productDetails1?.uploads.length === 0 && (
                      <img
                        src="/static/images/product1.png"
                        alt="Default Image"
                        style={{
                          borderRadius: 0,
                          marginBottom: "5px",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </Grid>

                <Grid item lg={2} md={2} sm={6} xs={12} />

                <Grid container spacing={3}>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    {/* <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Name
                    </Typography> */}
                    <Grid container spacing={3}>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Product Name</Typography>
                          <Typography component="p">
                            {productDetails1?.name || "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            UPC/Barcode Number
                          </Typography>
                          <Typography component="p">
                            {productDetails1?.barcode || "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">SKU</Typography>
                          <Typography component="p">
                            {productDetails1?.sku || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Category Type</Typography>
                          <Typography component="p">
                            {productDetails1?.categoryId?.name
                              ? productDetails1?.categoryId?.name
                              : "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Nutrition</Typography>
                          {productDetails1?.nutritions.map(
                            (nutrition: any, index: number) => (
                              <li
                                key={index}
                              >{`${nutrition.nutrient}: ${nutrition.value}`}</li>
                            )
                          )}
                        </Box>
                      </Grid>

                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Ingredients</Typography>

                          {productDetails1?.ingredients.map(
                            (ingredient: string, index: number) => (
                              <li key={index}>{ingredient}</li>
                            )
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">Date & Time</Typography>
                          <Typography component="p">
                            {formatDateTime(productDetails1?.createdAt)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">Description</Typography>
                          <Typography component="p">
                            {productDetails1?.description !== "<p></p>\n"
                              ? parseHTML(productDetails1?.description)
                              : "-"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="enlarged-image-modal"
        aria-describedby="enlarged-image-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: "90%",
            maxHeight: "90%",
            overflow: "auto",
            "&:focus": { outline: "none" },
          }}
        >
          <img
            src={selectedImage}
            alt="Enlarged"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Box>
      </Modal>
    </MainContainer>
  );
};

export default ProductDetails;
