import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUpdateUserMutation,
  useLazyGetUserByIdQuery,
} from "../../services/users";
import { showError, showToast } from "../../constants";
import { AllUser } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";

const UserEdit = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState<AllUser | null>(null);
  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [error, setError] = useState(false);

  const [getUserById] = useLazyGetUserByIdQuery();

  const [editUser, { isLoading }] = useUpdateUserMutation();

  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");

  const navigate = useNavigate();

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userData?.email || "",
      firstName: userData?.name || "",
      phone: userData?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      // address: Yup.string()
      //   .required("Address is required")
      //   .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        email: formik.values.email,
        name: formik.values.firstName,
        countryCode: phoneCode,
        phone: formik.values.phone,
        image: image,
        // address: formik.values.address,
      };
      try {
        const response = await editUser({ body, userId: `${id}` }).unwrap();
        showToast(response?.message || "User Updated Successfully");
        navigate("/manage-users");
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  const getUserDetailById = async (id: string | undefined) => {
    try {
      const response = await getUserById({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data);
        setPhoneCode(response?.data?.countryCode);
        setImage(response?.data?.image);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
    setCountryName(country?.countryCode);
  };

  useEffect(() => {
    getUserDetailById(id);
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit User Profile</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-users");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CloseIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.svg"
                              alt=""
                            />
                            <figure className="camera_btn">
                              <img
                                src="/static/images/camera_icon.svg"
                                alt=""
                              />
                            </figure>
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Name</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Full Name"
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      value={phoneCode + formik.values.phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                    ></TextField>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default UserEdit;
