import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useLazyGetUserByIdQuery } from "../../services/users";
import { AllUser } from "../../types/General";
import { showError } from "../../constants";
import ManageAllergies from "../manageAllergies";

const UserDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [getUserData] = useLazyGetUserByIdQuery();
  const [useDetails, setUserDetails] = useState<AllUser>();
  const [allergies, setAllergies] = useState<AllUser[]>([]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getUserDetailById = async (id: string | undefined) => {
    try {
      const response = await getUserData({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setUserDetails(response?.data);
        setAllergies(response?.data?.allergies);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getUserDetailById(id);
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>User Profile Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-users");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={2}>
                <figure className="profile_img">
                  <img
                    src={
                      useDetails?.image
                        ? useDetails?.image
                        : "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">
                        {useDetails?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone</Typography>
                      <Typography component="p">{`${useDetails?.countryCode} ${
                        useDetails?.phone || "-"
                      }`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {useDetails?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">
                        {useDetails?.isBlocked == false ? "Active" : "Inactive"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography className="detail_title" component="h2" mb={2}>
                      Plan Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Name</Typography>
                          <Typography component="p">Free</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Price</Typography>
                          <Typography component="p">$0,00</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography className="detail_title" component="h2" mb={2}>
                      Allergy Alert
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        gap: 10,
                        flexWrap: "wrap",
                        width: "90%",
                      }}
                    >
                      {allergies?.length
                        ? allergies?.map((item) => [
                            <List className="allergy_list" sx={{ p: 0 }}>
                              <ListItem>{item?.name}</ListItem>
                            </List>,
                          ])
                        : ""}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default UserDetail;
