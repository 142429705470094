import { END_POINTS } from "../constants/url";
import {
  Allergy,
  cms,
  Dashboard,
  Support,
  Notification,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: Allergy[];
  statusCode: number;
  message: string;
  totalPages: number;
};

type GetAllergyParams = {
  page: number;
  query: string;
  type?: number | string;
};

type AddAllergyBody = {
  name: string;
  image: string;
};

type ReplyBody = {
  queryId: string | undefined;
  body: {
    reply: string;
  };
};

type AddNotificationBody = {
  title: string;
  message: string;
};

type UpdateAllergyParams = {
  allergyId: string | undefined;
  body: {
    name: string;
    image: string;
  };
};

type UpdateRequestParams = {
  allergyId: string | undefined;
  body: {
    status: number;
  };
};

type EditCmsParams = {
  cmsId: string | undefined;
  body: {
    terms: string;
    privacy: string;
  };
};

type GetSupportParams = {
  page: number;
  query: string;
  isReplied: string | number;
};

type GetNotificationParams = {
  page: number;
  query: string;
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAllergy: builder.query<
      CommonResponseType & { data: any },
      GetAllergyParams
    >({
      query: ({ page, query, type }) => ({
        url:
          END_POINTS.allergy +
          "?page=" +
          page +
          "&search=" +
          query +
          "&type=" +
          type,
        method: "GET",
      }),
    }),

    addAllergy: builder.mutation<
      { message: string; statusCode: number; data: Allergy },
      AddAllergyBody
    >({
      query: (body) => ({
        url: END_POINTS.allergy,
        method: "POST",
        body,
      }),
    }),

    deleteAllergy: builder.mutation<CommonResponseType, { allergyId: string }>({
      query: ({ allergyId }) => ({
        url: `${END_POINTS.allergy}/${allergyId}`,
        method: "DELETE",
      }),
    }),

    changeAllergyStatus: builder.mutation<
      { message: string; statusCode: number },
      { allergyId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, allergyId }) => ({
        url: `${END_POINTS.allergy}/${allergyId}`,
        method: "PUT",
        body,
      }),
    }),

    updateAllergy: builder.mutation<CommonResponseType, UpdateAllergyParams>({
      query: ({ body, allergyId }) => ({
        url: `${END_POINTS.allergy}/${allergyId}`,
        method: "PUT",
        body,
      }),
    }),

    getAllergyById: builder.query<
      CommonResponseType & { data: any },
      { allergyId: string | undefined }
    >({
      query: ({ allergyId }) => ({
        url: `${END_POINTS.allergy}/${allergyId}`,
        method: "GET",
      }),
    }),

    updateAllergyRequest: builder.mutation<
      CommonResponseType,
      UpdateRequestParams
    >({
      query: ({ body, allergyId }) => ({
        url: `${END_POINTS.request}/${allergyId}`,
        method: "PUT",
        body,
      }),
    }),

    getDashboard: builder.query<
      CommonResponseType & { data: Dashboard },
      { type: string; type2: string }
    >({
      query: ({ type, type2 }) => ({
        url: `${END_POINTS.dashboard}?type=${type}&type2=${type2}`,
        method: "GET",
      }),
    }),

    getCms: builder.query<CommonResponseType & { data: cms }, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),

    editCms: builder.mutation<CommonResponseType, EditCmsParams>({
      query: ({ body, cmsId }) => ({
        url: `${END_POINTS.cms}/${cmsId}`,
        method: "PUT",
        body,
      }),
    }),

    getCustomerSupport: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          data: Support[];
          totalPages: number;
        };
      },
      GetSupportParams
    >({
      query: ({ page, query, isReplied }) => ({
        url:
          END_POINTS.contactSupport +
          "?search=" +
          query +
          "&page=" +
          page +
          "&isReplied=" +
          isReplied,
        method: "GET",
      }),
    }),

    addReply: builder.mutation<
      {
        message: string;
        statusCode: number;
        data: Support[];
      },
      ReplyBody
    >({
      query: ({ body, queryId }) => ({
        url: `${END_POINTS.replyUser}/${queryId}`,
        method: "POST",
        body,
      }),
    }),

    getAllNotification: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          data: Notification[];
          totalPages: number;
        };
      },
      GetNotificationParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.notification + "?search=" + query + "&page=" + page,
        method: "GET",
      }),
    }),

    addNotification: builder.mutation<
      { message: string; statusCode: number; data: Notification },
      AddNotificationBody
    >({
      query: (body) => ({
        url: END_POINTS.notification,
        method: "POST",
        body,
      }),
    }),

    deleteNotification: builder.mutation<
      CommonResponseType,
      { notificationId: string }
    >({
      query: ({ notificationId }) => ({
        url: `${END_POINTS.notification}/${notificationId}`,
        method: "DELETE",
      }),
    }),

    logout: builder.query<{ message: string; statusCode: number }, {}>({
      query: () => ({
        url: END_POINTS.logout,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllAllergyQuery,
  useAddAllergyMutation,
  useDeleteAllergyMutation,
  useChangeAllergyStatusMutation,
  useUpdateAllergyMutation,
  useLazyGetAllergyByIdQuery,
  useUpdateAllergyRequestMutation,
  useLazyGetDashboardQuery,
  useLazyGetCmsQuery,
  useEditCmsMutation,
  useLazyGetCustomerSupportQuery,
  useLazyGetAllNotificationQuery,
  useAddNotificationMutation,
  useAddReplyMutation,
  useLazyLogoutQuery,
  useDeleteNotificationMutation,
} = mainApi;
