import Compressor from "compressorjs";
import { getFromStorage } from "../constants/storage";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { UPLOAD_URL } from "../constants/url";

interface UploadResponse {
  statusCode: number;
  data: string;
  message: string;
}

export const UploadMedia = (imageObject: any): Promise<UploadResponse> => {
  return new Promise(async (resolve, reject) => {
    new Compressor(imageObject, {
      quality: 0.6,
      success: async (compressedResult) => {
        const formData = new FormData();
        formData.append("upload", compressedResult as any);

        let headers = {
          Accept: "application/json",
        };

        try {
          const res = await fetch(UPLOAD_URL, {
            method: "POST",
            headers,
            body: formData,
          });
          let response = await res.json();
          resolve(response); // Resolve the Promise with the response
        } catch (error) {
          console.log(error, ">>>>>>>>>");
          reject(error); // Reject the Promise with the error
        }
      },
    });
  });
};

export const Uploadpdf = async (imageObject: any) => {
  console.log("imageObject, ", imageObject);

  const formData = new FormData();

  formData.append("media", imageObject as any);

  const getToken = await getFromStorage(STORAGE_KEYS.token);
  const token = getToken ? JSON.parse(`${getToken}`) : null;
  let headers = {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    const res = await fetch(UPLOAD_URL, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};
