import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import List from "@mui/material/List";
import { useLazyGetSubscriptionByIdQuery } from "../../services/subscription";
import ListItem from "@mui/material/ListItem";
import { Subscription } from "../../types/General";
import { showError } from "../../constants";
import useAuth from "../../hooks/useAuth";

const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const { id } = useParams();
  const [getSubscriptionData, { isLoading }] =
    useLazyGetSubscriptionByIdQuery();
  const [subscriptionDetail, setSubscriptionDetail] = useState<Subscription>();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getSubsDetailById = async (id: string | undefined) => {
    try {
      const response = await getSubscriptionData({ subId: id }).unwrap();
      if (response?.statusCode === 200) {
        setSubscriptionDetail(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getSubsDetailById(id);
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Subscription Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscriptions");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12}>
                <figure className="profile_img">
                  <img
                    src={
                      subscriptionDetail?.image
                        ? subscriptionDetail?.image
                        : "/static/images/image_placeholder.jpg"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">
                        {subscriptionDetail?.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Monthly Price</Typography>
                      <Typography component="p">{`$${
                        subscriptionDetail?.monthlyPrice || "-"
                      }`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Quarterly Price</Typography>
                      <Typography component="p">{`$${
                        subscriptionDetail?.quarterlyPrice || "-"
                      }`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Yearly Price</Typography>
                      <Typography component="p">{`$${
                        subscriptionDetail?.yearlyPrice || "-"
                      }`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">
                        {subscriptionDetail?.description || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography className="detail_title" component="h2" mb={1}>
                      Features
                    </Typography>
                    <Grid container columnSpacing={2} rowSpacing={1}>
                      <Grid item xs={12}>
                        {subscriptionDetail?.features?.map(
                          (item: any, index: any) => (
                            <ul className="feature_list">
                              <Typography component="li">
                                {`${
                                  item?.count &&
                                  item?.type !== 3 &&
                                  item?.type !== 5
                                    ? item.count
                                    : ""
                                } ${
                                  item?.isUnlimited == true ? "Unlimited" : ""
                                } ${item?.name}`}
                              </Typography>
                            </ul>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default SubscriptionDetails;
