import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    TextField,
    Typography,
    Input,
  } from "@mui/material";
  import * as Yup from "yup";
  import { useFormik } from "formik";
  import MainContainer from "../../layout/MainContainer";
  import { useNavigate, useParams } from "react-router-dom";
  import CloseIcon from "@mui/icons-material/Close";
  import { ChangeEvent, useEffect, useState } from "react";
  import { showError, showToast } from "../../constants";
  import { UploadMedia } from "../../utils/mediaUpload";
  import { useAddCommunityMutation } from "../../services/community";
  import { isString } from "../../utils/validations";
import EditText from "../../components/EditText";
import { useLazyGetCategoryByIdQuery, useAddCategoryMutation, useEditCategoryByIdMutation } from "../../services/category";
import { ErrorOutline } from "@mui/icons-material";
  
  const AddCategory = () => {
    const {id}=useParams();
    const [displayEditText,setDisplayEditText]=useState(true);
    const navigate = useNavigate();
    const [addCommunity] = useAddCommunityMutation();
    const [description, setDescription] = useState<string>("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(0);
    const [image, setImage] = useState<string>("");
    // const [error, setError] = useState(false);
    const [fileName, setFileName] = useState<string>("");
    const [data,setData]=useState<any>({});
    const [getCategoryMethod, { isLoading }] = useLazyGetCategoryByIdQuery();
    const [addCategoryMethod] = useAddCategoryMutation();
    const [updateCategoryMethod] =  useEditCategoryByIdMutation();

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        title: "",
      },
      validationSchema: Yup.object({
        title: Yup.string()
          .required("This field is required")
          .max(40, "Maximum 40 character are allowed")
          .min(3, "Minimum 2 characters are required"),
      }),
      onSubmit: async () => {
        formik.setSubmitting(true);
  
        const body = {
          name: formik?.values?.title,
          image: image,
          description:description
        };
  
        // if (!image) {
        //   setError(true);
        //   return;
        // }
  
        try {
         if(id){
          const response = await updateCategoryMethod({id,body}).unwrap();
          if (response?.statusCode === 200) {
            showToast("Category Updated Successfully");
            formik.resetForm();
            navigate("/manage-category");
          } else {
            showError(response?.message || "");
          }
         }
         else
          { const response = await addCategoryMethod(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Category Added Successfully");
            formik.resetForm();
            navigate("/manage-category");
          } else {
            showError(response?.message || "");
          }}
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      },
    });

    const getCategoryById=async()=>{
      try{
        setDisplayEditText(false);
        const response=await getCategoryMethod({id:id}).unwrap();
        if(response.statusCode==200)
          {
            console.log(response?.data?.name)
            setData(response?.data)
            setDescription(response?.data?.description)
          }
      }
      catch(error:any)
      {
        showError(error?.data?.message);
      }
      setDisplayEditText(true);
    }
  useEffect(()=>{
    if(id){
    getCategoryById();
  }
  },[id])
  useEffect(()=>{
  if(id){  
    formik.setFieldValue('title',data?.name);
    setImage(data?.image)
  }
  },[data])
    const handleImageUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const files = event.target;
      const file = files?.files?.length ? files?.files[0] : "";
      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        setImage(res?.data);
      } else {
        showError(res?.message);
      }
    };
  
    return (
      <>
        <MainContainer>
          <div className="main_loyout">
            <div className="dashboard">
              <h1>Add Category</h1>
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-category");
                }}
              >
                Back
              </Button>
            </div>
            <Card className="cards">
              <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className="custom_label">Image</Typography>
                      {image ? (
                        <div className="upload_image_preview">
                          <CardMedia component="img" image={image} alt="photo" />
                          <CloseIcon
                            onClick={() => {
                              setImage("");
                            }}
                          />
                        </div>
                      ) : (
                        <Box className="upload_image">
                          <label htmlFor="icon-button-file">
                            <Input
                              sx={{ display: "none" }}
                              id="icon-button-file"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={handleImageUpload}
                            />
                            <Button component="span" className="upload_image_btn">
                              <img
                               style={{ borderRadius: 0 }}
                                src="/static/images/product1.png"
                                alt=""
                              />ß
                              <figure className="camera_btn">
                                <img
                                 
                                  src="/static/images/camera_icon.svg"
                                  alt=""
                                />
                              </figure>
                            </Button>
                          </label>
                        </Box>
                      )}
                      {/* {error && !image ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )} */}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Category Name
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="title"
                        inputProps={{ maxLength: 40 }}
                        variant="outlined"
                        fullWidth
                        value={formik.values.title}
                        placeholder=" Category Name"
                        className="text_field"
                        onBlur={formik.handleBlur}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isString(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={formik.touched.title && formik.errors.title}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                  <Typography className="dialog_title" style={{ paddingTop: '20px' }}>Description</Typography>
 
  <div style={{ width: '66%' }}>  
  { displayEditText ? <EditText
      content={description}
      setContent={setDescription}
    />:null}
  </div>
</Grid>
                  </Grid>
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  </div>
                </CardContent>
              </form>
            </Card>
          </div>
        </MainContainer>
      </>
    );
  };
  
  export default AddCategory;
