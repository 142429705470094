import { useNavigate, useParams } from "react-router-dom";
import React, { ReactEventHandler, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import {
  useLazyGetPostByIdQuery,
  useLazyGetAllCommentsQuery,
} from "../../services/community";
import MainContainer from "../../layout/MainContainer";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import GroupsIcon from "@mui/icons-material/Groups";
import { showError } from "../../constants";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import useAuth from "../../hooks/useAuth";

interface ScrollableModalProps {
  isOpen: boolean;
  handleClose: ReactEventHandler;
}

const ScrollableModal: React.FC<ScrollableModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const { id } = useParams();
  const [commentsData, setCommentsData] = useState<any>();
  const [getAllCommentsQuery] = useLazyGetAllCommentsQuery();
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const getAllComments = async () => {
    try {
      const response = await getAllCommentsQuery({
        postId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCommentsData(response?.data?.postComment);
        // setTotalCount(response?.data?.totalPages);
      } else {
        setCommentsData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const comments = [
    {
      id: 1,
      name: "diana embrose",
      comment:
        "I just saw a allergy signs today as i were having a product with niacinamide acid. Anybody else found the same?",
      image: "/static/images/user_placeholder.png",
    },
    {
      id: 2,
      name: "Emily Stephen",
      comment:
        "I just saw a allergy signs today as i were having a product with niacinamide acid. Anybody else found the same?",
      image: "/static/images/user_placeholder.png",
    },
  ];

  useEffect(() => {
    getAllComments();
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        style={{
          padding: "20px",
          maxHeight: "50%",
          overflowY: "auto",
          minWidth: "60%",
          maxWidth: "60%",
        }}
      >
        <Typography variant="h6">Comments</Typography>
        {commentsData?.length > 0 ? (
          commentsData?.map((item: any) => (
            <div style={{ borderBottom: "5px solid ", borderColor: "#E2E2E2" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  paddingTop: 15,
                }}
              >
                <img
                  src={
                    item?.userId?.image
                      ? item?.userId?.image
                      : "/static/images/user_placeholder.png"
                  }
                  style={{ height: 40, width: 40, borderRadius: 50 }}
                />
                <div style={{ lineHeight: 0.5 }}>
                  <p>{item?.userId?.name}</p>
                  <p style={{ fontSize: 12 }}>
                    {" "}
                    {moment(item?.createdAt).fromNow()}
                  </p>
                </div>
              </div>

              <div>
                <p style={{ fontSize: "14px" }}>{item?.text}</p>
              </div>

              {item?.replys?.length > 0 ? (
                <p style={{ paddingLeft: 20 }}>Replies:</p>
              ) : (
                ""
              )}

              {item?.replys?.map((val: any) => (
                <div style={{ paddingLeft: 60 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      paddingTop: 15,
                    }}
                  >
                    <img
                      src={
                        val?.userId?.image
                          ? val?.userId?.image
                          : "/static/images/user_placeholder.png"
                      }
                      style={{ height: 40, width: 40, borderRadius: 50 }}
                    />
                    <div style={{ lineHeight: 0.5 }}>
                      <p>{val?.userId?.name}</p>
                    </div>
                  </div>
                  <div>
                    <p style={{ fontSize: "14px" }}>{val?.text}</p>
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : (
          <p>No Comments</p>
        )}
        {/* ...more content */}
      </Paper>
    </Modal>
  );
};

const PostDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [getPostsById, { isLoading }] = useLazyGetPostByIdQuery();

  const [value, setValue] = React.useState(0);
  const [postsData, setPostsData] = useState<any>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [communityId, setCommunityId] = useState("");
  console.log(communityId);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getPostsDetails = async (id: string | undefined) => {
    try {
      const response = await getPostsById({ postId: id }).unwrap();
      if (response?.statusCode === 200) {
        setPostsData(response?.data);
        setCommunityId(response?.data?.community?._id);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getPostsDetails(id);
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Post Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate(`/manage-community/details/${communityId}`);
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="view_box_list">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box sx={{ backgroundColor: "#D5EBE8", borderRadius: 3 }}>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            paddingLeft: 2,
                            paddingBottom: 2,
                            paddingRight: 2,
                          }}
                        >
                          <Grid item xs={10}>
                            <Box>
                              <Typography component="h5">Title</Typography>
                              <Typography component="p">
                                {postsData?.title || "-"}
                              </Typography>

                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography
                                    component="h5"
                                    sx={{ paddingTop: 1 }}
                                  >
                                    Author Name
                                  </Typography>
                                  <Typography component="p">
                                    {postsData?.userId?.name || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography
                                    component="h5"
                                    sx={{ paddingTop: 1 }}
                                  >
                                    People in Community
                                  </Typography>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 5,
                                    }}
                                  >
                                    <Typography component="p">
                                      {postsData?.noOfUsersInCommunity || "0"}
                                    </Typography>
                                    <GroupsIcon fontSize="small" />
                                  </div>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography
                                    component="h5"
                                    sx={{ paddingTop: 1 }}
                                  >
                                    Likes
                                  </Typography>

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 5,
                                    }}
                                  >
                                    <Typography component="p">
                                      {postsData?.numberOfLikes || "0"}
                                    </Typography>
                                    <ThumbUpIcon fontSize="small" />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid item xs={5}>
                                <div className="form_btn">
                                  <Button
                                    size="small"
                                    className="btn btn_primary"
                                    onClick={handleButtonClick}
                                  >
                                    Comments
                                  </Button>
                                </div>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <div className="card_main" style={{ width: "100%" }}>
                        {postsData?.photo ? (
                          <div className="card">
                            <img
                              src={postsData?.photo}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="card_main" style={{ width: "100%" }}>
                        {postsData?.video && (
                          <div className="card">
                            <figure>
                              <video controls width="100%" height="100%">
                                <source
                                  src={postsData?.video}
                                  type="video/mp4"
                                />
                              </video>
                            </figure>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">
                        {postsData?.description || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <ScrollableModal isOpen={isModalOpen} handleClose={handleCloseModal} />
    </MainContainer>
  );
};

export default PostDetails;
