import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLazyGetCmsQuery, useEditCmsMutation } from "../../services/main";
import "react-phone-input-2/lib/bootstrap.css";
import { Loader, showError, showToast } from "../../constants";
import PhoneInput from "react-phone-input-2";
import useAuth from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const [getAllCms, { isLoading }] = useLazyGetCmsQuery();
  const [editCmsMutation] = useEditCmsMutation();

  const [phoneCode, setPhoneCode] = useState("+1");
  console.log(phoneCode);
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [terms, setTerms] = useState<string>("");
  const [value, setValue] = React.useState(0);
  const [cmsId, setCmsId] = useState<string>("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "CMS"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  const formik = useFormik({
    initialValues: { email: "", phone: "" },

    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Please enter a valid email"
        ),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters"),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: "+1",
        terms: terms,
        privacy: privacyPolicy,
      };

      console.log(body);

      try {
        const response = await editCmsMutation({
          cmsId: cmsId,
          body,
        }).unwrap();

        if (response?.statusCode === 200) {
          showToast("Cms Update Successfully");
        }
      } catch (error: any) {
        console.log(error, "error");
        showError(error?.data?.message || "");
      }
    },
  });

  const getCmsDetail = async () => {
    try {
      const res = await getAllCms({}).unwrap();
      if (res?.statusCode === 200 && res?.data) {
        formik.setFieldValue("email", res?.data?.email);
        formik.setFieldValue("phone", res?.data?.phone);
        setPrivacyPolicy(res?.data?.privacy || " ");
        setTerms(res?.data?.terms);
        setCmsId(res?.data?._id);
        setPhoneCode(res?.data?.countryCode || "");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Terms and conditions" {...a11yProps(1)} />
              <Tab label="Privacy policy" {...a11yProps(2)} />
              {/* <Tab label="FAQ's" {...a11yProps(2)} /> */}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      className="text_field"
                      placeholder="Email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 50 }}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      placeholder="Phone Number"
                      value={phoneCode + formik.values.phone}
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                {/* <Box className="cards_header_right">
            {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-product/add")}
              >
                Add Product
              </Button>
               ) : (
                <></>
              )}
            </Box> */}
            {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
                   ) : (
                    <></>
                  )}
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                {terms ? (
                  <EditText content={terms} setContent={setTerms} />
                ) : (
                  ""
                )}

{userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
                   ) : (
                    <></>
                  )}
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
              {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
                   ) : (
                    <></>
                  )}
              </form>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={2}>
              <Faq />
            </CustomTabPanel> */}
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
