import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LineChart from "../../components/LineChart";
import { useLazyGetDashboardQuery } from "../../services/main";
import { Dashboard } from "../../types/General";
import { Loader } from "../../constants";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const navigate = useNavigate();
  const [getDashboard, { isLoading }] = useLazyGetDashboardQuery();
  const [dashboardDetails, setDashboardDetails] = useState<Dashboard>();
  const [earningNames, setEarningNames] = useState<string[]>([]);
  const [earningValues, setEarningValues] = useState<number[]>([]);
  const [userNames, setUserNames] = useState<[]>([]);
  const [userValues, setUserValues] = useState<[]>();
  const [userFilter, setUserFilter] = useState<string>("month");
  const [earningFilter, setEarningFilter] = useState<string>("Monthly");

  const handleOrderChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };

  const getDashboardDetails = async () => {
    try {
      const response = await getDashboard({
        type: userFilter.toUpperCase(),
        type2: earningFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setDashboardDetails(response?.data);
        setEarningNames(response?.data?.Brr);
        // setEarningValues(Object.values(response?.data?.earnings));
        setUserNames(response?.data?.users);
        setUserValues(response?.data?.users);
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
    }
  };

  const names = userNames.map((item: any) => item.name);
  const values = userNames.map((item: any) => item.total);

  const rnames = earningNames.map((item: any) => item.name);
  const rvalues = earningNames.map((item: any) => item.value);

  const graphUsersData = {
    labels: names,
    datasets: [
      {
        label: "Users",
        data: values,
        borderColor: "#ccbc4f",
        backgroundColor: "#ccbc4f",
      },
    ],
  };
  const graphRevenueData = {
    labels: rnames,
    datasets: [
      {
        label: "Earnings",
        data: rvalues,
        borderColor: "#ccbc4f",
        backgroundColor: "#ccbc4f",
      },
    ],
  };

  useEffect(() => {
    getDashboardDetails();
  }, [userFilter, earningFilter]);

  return (
    <div className="main_loyout">
      <Loader isLoad={isLoading} />
      <div className="dashboard">
        <h1>Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item xs={4}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-users")}
            sx={{ cursor: "pointer" }}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Customers</h3>
              <h4>{dashboardDetails?.totalUsers || "-"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item className="cards dashGrid_item">
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>New Customers</h3>
              <h4>{dashboardDetails?.newUsers || "0"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item className="cards dashGrid_item">
            <AttachMoneyIcon className="svg_icn" />
            <div>
              <h3>Total Earnings</h3>
              <h4>
                {dashboardDetails?.totalEarnings?.total !== undefined
                  ? dashboardDetails?.totalEarnings?.total >= 1000000
                    ? `$ ${(
                        dashboardDetails?.totalEarnings?.total / 1000000
                      ).toFixed(1)} m`
                    : Math.round(
                        dashboardDetails?.totalEarnings?.total
                      ).toLocaleString() // Add toLocaleString() here
                  : "-"}
              </h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item xs={6}>
          <Item className="cards dashGraph_item">
            <h2>
              Total Users
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleOrderChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="week">Weekly</MenuItem>
                  <MenuItem value="month">Monthly</MenuItem>
                  <MenuItem value="year">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphUsersData} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item className="cards dashGraph_item">
            <h2>
              Total Earnings
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={earningFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleEarningChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
