import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  Switch,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useLazyGetSubscriptionByIdQuery,
  useUpdateSubscriptionMutation,
} from "../../services/subscription";
import { Subscription } from "../../types/General";
import { showError, showToast } from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import { isNumber } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";

const EditSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getSubscriptionData, { isLoading }] =
    useLazyGetSubscriptionByIdQuery();
  const [editSubscriptionMutation] = useUpdateSubscriptionMutation();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [image, setImage] = useState<string>("");
  const [subscriptionDetail, setSubscriptionDetail] = useState<Subscription>();
  const [features, setFeatures] = useState([{ feature: "" }]);
  const [error, setError] = useState(false);
  const [count1, setCount1] = useState("");
  const [count2, setCount2] = useState("");
  const [count3, setCount3] = useState("");
  const [unlimited, setUnlimited] = useState(false);
  const [unlimited1, setUnlimited1] = useState(false);
  const [unlimited2, setUnlimite2] = useState(false);
  const [enable, setEnable] = useState(false);
  const [enable1, setEnabl1] = useState(false);
  const [enable2, setEnable2] = useState(false);
  const [enable3, setEnable3] = useState(false);
  const [enable4, setEnable4] = useState(false);
  const [enable5, setEnable5] = useState(false);
  const [enable6, setEnable6] = useState(false);
  const [planType, setPlanType] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const abb = [
    {
      id: 1,
      name: "scans/month",
      count: Number(count1),
      isUnlimited: unlimited,
      enable: enable,
      type: 1,
    },
    {
      id: 2,
      name: "history saves/month",
      count: Number(count2),
      isUnlimited: unlimited1,
      enable: enable1,
      type: 2,
    },
    {
      id: 3,
      name: "allergy communities",
      count: Number(count3),
      isUnlimited: unlimited2,
      enable: enable2,
      type: 3,
    },
    {
      id: 4,
      name: "Patient profile alerts",
      enable: enable3,
      isCount: false,
      toggle: false,
      type: 4,
    },
    {
      id: 5,
      name: "Share care plan",
      enable: enable4,
      type: 5,
    },
    {
      id: 6,
      name: "Alert saved contacts",
      enable: enable5,
      type: 11,
    },
    {
      id: 7,
      name: "Medication Expiry Alert",
      enable: enable6,
      type: 12,
    },
  ];

  const handleChange = () => {
    setUnlimited(!unlimited);
  };
  const handleChange1 = () => {
    setUnlimited1(!unlimited1);
  };
  const handleChange2 = () => {
    setUnlimite2(!unlimited2);
  };

  const handleEnable = () => {
    setEnable(!enable);
  };
  const handleEnable1 = () => {
    setEnabl1(!enable1);
  };
  const handleEnable2 = () => {
    setEnable2(!enable2);
  };
  const handleEnable3 = () => {
    setEnable3(!enable3);
  };
  const handleEnable4 = () => {
    setEnable4(!enable4);
  };
  const handleEnable5 = () => {
    setEnable5(!enable5);
  };
  const handleEnable6 = () => {
    setEnable6(!enable6);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: subscriptionDetail?.name || "",
      description: subscriptionDetail?.description || "",
      monthlyPrice: subscriptionDetail?.monthlyPrice || "0",
      quarterlyPrice: subscriptionDetail?.quarterlyPrice || "0",
      yearlyPrice: subscriptionDetail?.price || "0",
      productId: subscriptionDetail?.productId,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      productId: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 2 characters are required"),
      monthlyPrice: Yup.string().required("This field is required"),

      quarterlyPrice: Yup.string().required("This field is required"),
      yearlyPrice: Yup.string().required("This field is required"),
    }),
    onSubmit: async () => {
      const nonEmptyFeatures = features.filter(
        (feature) => feature.feature !== ""
      );

      formik.setSubmitting(true);

      const body = {
        name: formik?.values?.name,
        description: formik?.values?.description,
        planType: Number(planType),
        subscriptionType: Number(subscriptionType),
        price: formik?.values?.yearlyPrice,
        features: abb,
        productId: formik.values.productId,
      };

      if (!image) {
        setError(true);
        return;
      }

      try {
        const response = await editSubscriptionMutation({
          body,
          subsId: id,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast("Subscription Updated Successfully");
          formik.resetForm();
          navigate("/manage-subscriptions");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handleAddFeature = () => {
    const newFeatures = [...features, { id: features.length, feature: "" }];
    setFeatures(newFeatures);
  };

  const handleRemoveFeature = (index: number) => {
    const updatedFeatures = features.filter((_, i) => i !== index);
    setFeatures(updatedFeatures);
  };

  const handlePlanChange = (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string);
  };

  const handleSubsChange = (event: SelectChangeEvent) => {
    setSubscriptionType(event.target.value as string);
  };

  const handleFeatureChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const updatedFeatures = features.map((feature, i) => {
      if (i === index) {
        return { ...feature, feature: event.target.value };
      }
      return feature;
    });
    setFeatures(updatedFeatures);
  };

  const getSubsDetailById = async (id: string | undefined) => {
    try {
      const response = await getSubscriptionData({ subId: id }).unwrap();
      if (response?.statusCode === 200) {
        setSubscriptionDetail(response?.data);
        setImage(response?.data?.image);
        setFeatures(response?.data?.features);
        setUnlimited(response?.data?.features[0]?.isUnlimited);
        setUnlimited1(response?.data?.features[1]?.isUnlimited);
        setUnlimite2(response?.data?.features[2]?.isUnlimited);
        setCount1(response?.data?.features[0]?.count);
        setCount2(response?.data?.features[1]?.count);
        setCount3(response?.data?.features[2]?.count);
        setEnable(response?.data?.features[0]?.enable);
        setEnabl1(response?.data?.features[1]?.enable);
        setEnable2(response?.data?.features[2]?.enable);
        setEnable3(response?.data?.features[3]?.enable);
        setEnable4(response?.data?.features[4]?.enable);
        setEnable5(response?.data?.features[5]?.enable);
        setEnable6(response?.data?.features[6]?.enable);
        setPlanType(response?.data?.planType);
        setSubscriptionType(response?.data?.subscriptionType);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getSubsDetailById(id);
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Subscription</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subscriptions");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Subscription Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      className="text_field"
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                    ></TextField>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className="custom_label">
                      Subscription Description
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="description"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Description"
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    ></TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">Price</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="yearlyPrice"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Price"
                      onBlur={formik.handleBlur}
                      value={formik.values.yearlyPrice}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.yearlyPrice && formik.errors.yearlyPrice
                      }
                    ></TextField>
                  </Grid>

                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">Plan Type</Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        className="select_div2"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={planType}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handlePlanChange}
                      >
                        <MenuItem value="" disabled>
                          Select Plan Type
                        </MenuItem>
                        <MenuItem value="1">Monthly</MenuItem>
                        <MenuItem value="2">Quarterly</MenuItem>
                        <MenuItem value="3">Half Yearly</MenuItem>
                        <MenuItem value="4">Yearly</MenuItem>
                      </Select>
                      {error && !planType ? (
                        <h6 className="err_msg">Please select Plan Type</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      Subscription Type
                    </Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        className="select_div2"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subscriptionType}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleSubsChange}
                      >
                        <MenuItem value="" disabled>
                          Select Subscription Type
                        </MenuItem>
                        <MenuItem value="1">Free</MenuItem>
                        <MenuItem value="2">Paid</MenuItem>
                      </Select>
                      {error && !planType ? (
                        <h6 className="err_msg">Please select Plan Type</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Subscription Id
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="productId"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Subscription Id"
                      onBlur={formik.handleBlur}
                      value={formik.values.productId}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.productId && formik.errors.productId
                      }
                    ></TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className="custom_title"
                      component="h5"
                      sx={{ pb: 2 }}
                    >
                      Features
                    </Typography>
                    {/* <Grid item xs={12}>
                      <TableContainer
                        className="table_container"
                        sx={{ margin: 0 }}
                      >
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">S.No</TableCell>
                              <TableCell>Feature</TableCell>
                              <TableCell>Count</TableCell>
                              <TableCell>Unlimited</TableCell>
                              <TableCell> Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortedFeatures?.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell align="center">{i + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell sx={{ width: 200 }}>
                                  {row?.isCount ? (
                                    <TextField
                                      hiddenLabel
                                      type={"text"}
                                      name="yearlyPrice"
                                      className="text_field"
                                      variant="outlined"
                                      fullWidth
                                      placeholder="Count"
                                      onChange={(val) =>
                                        setCount(val.target.value)
                                      }
                                    ></TextField>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {row?.toggle ? (
                                    <Switch
                                      {...label}
                                      defaultChecked={row?.isUnlimited}
                                      onChange={() =>
                                        handleToggleUnlimited(row)
                                      }
                                      size="small"
                                      // value={true}
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Switch
                                    {...label}
                                    checked={selectedFeatures.some(
                                      (f) => f.id === row.id
                                    )}
                                    size="small"
                                    onChange={() => handleStatusToggle(row)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">1.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              scans/month
                            </Typography>
                          </Box>
                          <TextField
                            sx={{ width: "20%" }}
                            hiddenLabel
                            type={"text"}
                            name="yearlyPrice"
                            className="text_field"
                            variant="outlined"
                            fullWidth
                            placeholder="count"
                            value={count1}
                            onChange={(val) => {
                              const inputVal = val.target.value;

                              // Use a regular expression to check if the input is a valid number
                              if (/^\d*$/.test(inputVal)) {
                                setCount1(inputVal);
                              }
                            }}
                            // helperText={
                            //   formik.touched.yearlyPrice &&
                            //   formik.errors.yearlyPrice
                            // }
                          ></TextField>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Unlimited :
                            </Typography>
                            <Switch
                              {...label}
                              checked={unlimited}
                              onChange={handleChange}
                              size="small"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable}
                              onChange={handleEnable}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">2.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              history saves / month
                            </Typography>
                          </Box>
                          <TextField
                            sx={{ width: "20%" }}
                            hiddenLabel
                            type={"text"}
                            name="yearlyPrice"
                            className="text_field"
                            variant="outlined"
                            fullWidth
                            placeholder="count"
                            value={count2}
                            onChange={(val) => {
                              const inputVal = val.target.value;

                              // Use a regular expression to check if the input is a valid number
                              if (/^\d*$/.test(inputVal)) {
                                setCount2(inputVal);
                              }
                            }}
                            // helperText={
                            //   formik.touched.yearlyPrice &&
                            //   formik.errors.yearlyPrice
                            // }
                          ></TextField>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Unlimited :
                            </Typography>
                            <Switch
                              {...label}
                              checked={unlimited1}
                              onChange={handleChange1}
                              size="small"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable1}
                              onChange={handleEnable1}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">3.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              allergy communities
                            </Typography>
                          </Box>
                          <TextField
                            sx={{ width: "20%" }}
                            hiddenLabel
                            type={"text"}
                            name="yearlyPrice"
                            className="text_field"
                            variant="outlined"
                            fullWidth
                            placeholder="count"
                            value={count3}
                            onChange={(val) => {
                              const inputVal = val.target.value;

                              // Use a regular expression to check if the input is a valid number
                              if (/^\d*$/.test(inputVal)) {
                                setCount3(inputVal);
                              }
                            }}
                            // helperText={
                            //   formik.touched.yearlyPrice &&
                            //   formik.errors.yearlyPrice
                            // }
                          ></TextField>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Unlimited :
                            </Typography>
                            <Switch
                              {...label}
                              checked={unlimited2}
                              onChange={handleChange2}
                              size="small"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable2}
                              onChange={handleEnable2}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">4.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              Patient profile alerts
                            </Typography>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable3}
                              onChange={handleEnable3}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">5.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              Share care plan
                            </Typography>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable4}
                              onChange={handleEnable4}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">6.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              Alert saved contacts
                            </Typography>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable5}
                              onChange={handleEnable5}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={10}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Typography className="custom_label">7.</Typography>
                          <Box sx={{ width: 150 }}>
                            <Typography className="custom_label">
                              Medication Expiry Alert
                            </Typography>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 10,
                            }}
                          >
                            <Typography className="custom_label">
                              Enable :
                            </Typography>
                            <Switch
                              {...label}
                              checked={enable6}
                              onChange={handleEnable6}
                              size="small"
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    {/* <Grid
                      container
                      direction="column"
                      alignItems="start"
                      rowSpacing={1}
                      columnSpacing={2}
                    >
                      {features.map((feature, index) => (
                        <Grid item key={index} sx={{ width: "50%" }}>
                          <div className="feature_input">
                            <TextField
                              hiddenLabel
                              type="text"
                              name="features"
                              id="features"
                              variant="outlined"
                              fullWidth
                              placeholder={`Feature ${index + 1}`}
                              value={feature?.feature}
                              onChange={(event) =>
                                handleFeatureChange(event, index)
                              }
                            />
                            {index === features.length - 1 ? (
                              <Button
                                disabled={!feature?.feature}
                                className="btn"
                                onClick={handleAddFeature}
                              >
                                <AddIcon />
                              </Button>
                            ) : (
                              <Button
                                className="btn"
                                onClick={() => handleRemoveFeature(index)}
                              >
                                <RemoveIcon />
                              </Button>
                            )}
                          </div>
                          <div>
                            <p> {featureError ? "error" : ""}</p>
                          </div>
                        </Grid>
                      ))}
                    </Grid> */}
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Typography className="custom_title" component="h5">
                      Features
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      alignItems="start"
                      rowSpacing={1}
                      columnSpacing={2}
                    >
                      {features.map((feature, index) => (
                        <Grid item key={index} sx={{ width: "50%" }}>
                          <div className="feature_input">
                            <TextField
                              hiddenLabel
                              type="text"
                              name={`feature-${index}`}
                              variant="outlined"
                              fullWidth
                              placeholder={`Feature ${index + 1}`}
                              value={feature.feature}
                              onChange={(event) =>
                                handleFeatureChange(event, index)
                              }
                            />
                            {index === features.length - 1 ? (
                              <Button
                                disabled={!feature?.feature}
                                className="btn"
                                onClick={handleAddFeature}
                              >
                                <AddIcon />
                              </Button>
                            ) : (
                              <Button
                                className="btn"
                                onClick={() => handleRemoveFeature(index)}
                              >
                                <RemoveIcon />
                              </Button>
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid> */}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default EditSubscription;
