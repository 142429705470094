import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isValidInput } from "../../utils/validations";
import {
  useLazyGetAllCommunityQuery,
  useDeleteCommunityMutation,
} from "../../services/community";
import { showError, showToast } from "../../constants";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";

const ManageCommunity = () => {
  const navigate = useNavigate();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState(1);
  const [getCommunityData, { isLoading }] = useLazyGetAllCommunityQuery();
  const [deleteCommunity] = useDeleteCommunityMutation();
  const [communityData, setCommunityData] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [usersInComm, setUsersInComm] = useState("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    console.log("per",permissions);
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Community"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil(totalCount / 10);

  const getCommunity = async () => {
    try {
      const response = await getCommunityData({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCommunityData(response?.data?.community);
        setTotalCount(response?.data?.totalcommunity);
        setUsersInComm(response?.data?.community?.noOfUsersInCommunity);
      } else {
        setCommunityData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  function createData(
    Name: string,
    Description: string,
    Monthly: string,
    Quarterly: string,
    Yearly: string
  ) {
    return { Name, Description, Monthly, Quarterly, Yearly };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Free Plan", "Lorem ipsum dolor...", "$0.00", "$0.00", "$0.00"),
    createData(
      "Premium Plan",
      "Lorem ipsum dolor...",
      "$14.00",
      "$50.00",
      "$120.00"
    ),
  ];

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCommunity({ communityId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Community deleted successfully");
        getCommunity();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getCommunity();
  }, [page, debouncedSearchTerm]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Community</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
             {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-community/add")}
              >
              Add Community
              </Button>
               ) : (
                <></>
              )}
            {/* <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-community/add")}
              >
                Add Community
              </Button>
            </Box> */}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>People in Community</TableCell>

                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {communityData?.length > 0 ? (
                  communityData.map((row: any, i: any) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>
                        <figure className="icon_img">
                          <img
                            src={
                              row?.image
                                ? row?.image
                                : "/static/images/image_placeholder.jpg"
                            }
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{row.name || "-"}</TableCell>
                      <TableCell>{row?.noOfUsersInCommunity || "-"}</TableCell>

                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-community/details/${row?._id}`)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-community/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                           ) : (
                            <></>
                          )}
                          {userData?.role === "ADMIN" ||
                            hidePermission?.isDelete ? (
<IconButton onClick={() => {
  setSelectedId(row?._id);
  setOpen(true);
}}
disabled={row?.noOfUsersInCommunity !== 0}>
  <DeleteIcon />
</IconButton>
 ) : (
  <></>
)}
                          {/* <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                            disabled={row?.noOfUsersInCommunity !== 0}
                          >
                            <DeleteIcon />
                          </IconButton> */}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No User Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={communityData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Community"
      />
    </MainContainer>
  );
};

export default ManageCommunity;
