import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import { isValidInput } from "../../utils/validations";
import {
  useLazyGetAllNotificationQuery,
  useDeleteNotificationMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { Notification } from "../../types/General";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";

const ManageNotifications = () => {
  const navigate = useNavigate();
  const [getNotificationData, { isLoading }] = useLazyGetAllNotificationQuery();
  const [deleteNotification] = useDeleteNotificationMutation();

  const [notification, setNotification] = useState<Notification[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Notifications"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const getNotification = async () => {
    try {
      const response = await getNotificationData({
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setNotification(response?.data?.data);
        setTotalCount(response?.data?.totalPages);
      } else {
        setNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteNotification({
        notificationId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Notification deleted successfully");
        getNotification();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  function createData(Title: string, Message: string, Platform: string) {
    return { Title, Message, Platform };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Title",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      "All"
    ),
  ];

  useEffect(() => {
    getNotification();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
             <Box className="cards_header_right">
            {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-notifications/add")}
              >
                Add Notification
              </Button>
               ) : (
                <></>
              )}
            </Box>
            
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell style={{ width: "100px" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notification?.length ? (
                  notification.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row?.title || "-"}</TableCell>
                      <TableCell
                        style={{
                          whiteSpace: "pre-wrap",
                          overflow: "hidden",
                        }}
                      >
                        {row?.message || "-"}
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          {/* <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton> */}
                          {userData?.role === "ADMIN" ||
                            hidePermission?.isDelete ? (
<IconButton onClick={() => {
  setSelectedId(row?._id);
  setOpen(true);
}}>
  <DeleteIcon />
</IconButton>
 ) : (
  <></>
)}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No Notification Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={notification}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalCount}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Notification"
      />
    </MainContainer>
  );
};

export default ManageNotifications;
