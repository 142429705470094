import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import Box from "@mui/material/Box";
import { Allergy, RequestStatus } from "../../types/General";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import WarnModal from "../../components/WarnModal";

import {
  useLazyGetAllAllergyQuery,
  useDeleteAllergyMutation,
  useChangeAllergyStatusMutation,
  useUpdateAllergyRequestMutation,
} from "../../services/main";
import DeleteIcon from "@mui/icons-material/Delete";
import { Loader, showError, showToast } from "../../constants";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageAllergies = () => {
  const navigate = useNavigate();
  const [getAllAllergy, { isLoading }] = useLazyGetAllAllergyQuery();
  const [updateRequest] = useUpdateAllergyRequestMutation();
  const [deleteAllergy] = useDeleteAllergyMutation();
  const [changeStatus] = useChangeAllergyStatusMutation();

  const [allergyData, setAllergyData] = useState<Allergy[]>([]);
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Allergies"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };

  const getAllergyManagement = async () => {
    try {
      const response = await getAllAllergy({
        page: page,
        query: debouncedSearchTerm.trim(),
        type: value == 1 ? "allergyRequests" : "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllergyData(response?.data?.data || []);
        setTotalCount(response?.data?.totalPages);
      } else {
        setAllergyData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteAllergy({ allergyId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Allergy deleted successfully");
        getAllergyManagement();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, isBlocked: boolean) => {
    const body = {
      isBlocked: isBlocked == true ? false : true,
    };

    try {
      const response = await changeStatus({ allergyId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        getAllergyManagement();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequestStatus = async (event: SelectChangeEvent, id: string) => {
    const statusValue = Number(event.target.value);
    const body = {
      status: statusValue,
    };

    try {
      const response = await updateRequest({ allergyId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast(
          statusValue === 1
            ? "Request accepted successfully"
            : "Request rejected successfully"
        );
        getAllergyManagement();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  function createData(AllergyName: string) {
    return { AllergyName };
  }
  const rows = [createData("Milk")];

  function createData1(
    UserName: string,
    UserPhone: string,
    UserEmail: string,
    AllergyName: string
  ) {
    return { UserName, UserPhone, UserEmail, AllergyName };
  }
  const rows1 = [
    createData1(
      "Gurinder Singh",
      "9876543210",
      "gurinder@apptunix.com",
      "Meat"
    ),
  ];

  // useEffect(() => {
  //   getAllergyManagement();
  // }, [value, debouncedSearchTerm, page]);
  useEffect(() => {
    getAllergyManagement();
    return () => {
      setAllergyData([]);  
    };
  }, [value, debouncedSearchTerm, page]);
  
  return (
    <MainContainer>
      {/* <Loader isLoad={isLoading} /> */}
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Allergies</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Allergies" {...a11yProps(0)} />
              <Tab label="Added Allergies" {...a11yProps(1)} />
            </Tabs>
            {!isLoading && (
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                {/* <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-allergies/add")}
                  >
                    Add Allergy
                  </Button>
                </Box> */}
                            <Box className="cards_header_right">
            {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-allergies/add")}
              >
                Add Allergy
              </Button>
               ) : (
                <></>
              )}
            </Box>

                
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Allergy Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allergyData?.length ? (
                      allergyData?.map((row: any, i: any) => (
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>
                            <figure className="icon_img">
                              <img
                                src={
                                  row?.image
                                    ? row?.image
                                    : "/static/images/image_placeholder.jpg"
                                }
                                alt=""
                              />
                            </figure>
                          </TableCell>
                          <TableCell>{row?.name}</TableCell>
                          <TableCell>
                            <Switch
                              {...label}
                              size="small"
                              checked={!row?.isBlocked}
                              onChange={() =>
                                updateStatus(row?._id, row?.isBlocked)
                              }
                            />
                          </TableCell>
                          <TableCell>
                               
                            <Box className="table_actions">
                            {userData?.role === "ADMIN" ||
                            hidePermission?.isEdit ? (
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-allergies/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                               ) : (
                                <></>
                              )}
                               {userData?.role === "ADMIN" ||
                            hidePermission?.isDelete ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                               ) : (
                                <></>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Allergy Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            )}
            {!isLoading && 
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>User Phone</TableCell>
                      <TableCell>User Email</TableCell>
                      <TableCell>Allergy Name</TableCell>
                      {/* <TableCell>Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                    {allergyData?.length ? (
                      allergyData?.map((row: any, i: any) => (
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.userId?.name || "-"}</TableCell>
                          <TableCell>{`${row?.userId?.countryCode} ${
                            row.userId?.phone || "-"
                          }`}</TableCell>
                          <TableCell>{row?.userId?.email || "-"}</TableCell>
                          <TableCell>{row?.name || "-"}</TableCell>
                          {/* <TableCell>
                            <FormControl>
                              <Select
                                className="select_div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={row?.status > 0 ? row?.status : ""}
                                onChange={(val) =>
                                  handleRequestStatus(val, row?._id)
                                }
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                              >
                                <MenuItem value="" disabled>
                                  Select
                                </MenuItem>
                                {RequestStatus?.slice(1, 3).map((item, i) => (
                                  <MenuItem key={i} value={item?.id}>
                                    {item?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell> */}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Allergy Request Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
}
          </Box>
        </Card>
       
{!isLoading && (
  <Pagination
    setPage={setPage}
    module={allergyData}
    page={page}
    onPageChange={onPageChange}
    totalPages={totalCount}
  />
)}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Allergy"
      />
    </MainContainer>
  );
};

export default ManageAllergies;
