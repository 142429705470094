import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Visibility } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isValidInput } from "../../utils/validations";
import { Loader, showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { useLazyGetCategoryQuery, useEditCategoryByIdMutation, useDeleteCategoryByIdMutation } from "../../services/category";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
const ManageCategory = () => {
  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin", "Category@gmail.com", "+971056734321")];
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [categoryList, setCategoryList] = useState<any[]>(
    []
  );
  const [getCategoryList, { isLoading }] = useLazyGetCategoryQuery();
  const [updateCategory, CategoryStatusData] =
  useEditCategoryByIdMutation();
  const [deleteCategoryById, deleteCategoryByIdData] =
  useDeleteCategoryByIdMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const userData = useAuth();
  console.log(categoryList);
  const getCategory = async () => {
    try {
      const response = await getCategoryList({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryList(response?.data?.category || []);
        setTotalCount(response?.data?.totalCategory
        );
      } else {
        setCategoryList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const updateStatus = async (id: string, state: boolean) => {
    try {
      const response = await updateCategory({
        id: id,
        body: {
          isBlocked: !state,
        },
      }).unwrap();
      if (response?.statusCode === 200) {
        getCategory();
        showToast(response?.message || "Status Updated Successfully");
      } else {
        showError("Please try again");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategoryById({ id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Category deleted successfully");
        getCategory();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getCategory();
  }, [debouncedSearchTerm, page]);
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> Manage Category</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
           <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-category/add")}
              >
                Add Category
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                   
                  {/* <TableCell>Title</TableCell> */}
                    <TableCell>Category Name</TableCell>
                    {/* <TableCell>Description</TableCell> */}
                 
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryList.map((row:any, i:number) => (
                  <TableRow>
                    <TableCell align="center">{(page-1)*10 +i + 1}</TableCell>
                    <TableCell>
                        <figure className="user_img">
                          <img  style={{ borderRadius: 0 }} src={row?.image?row?.image:"/static/images/category.png"} alt="" />
                        </figure>
                      </TableCell>
                 
                    <TableCell>{row?.name}</TableCell>
                        {/* <TableCell>Body Scan</TableCell> */}
                        {/* <TableCell>   3 times per a day </TableCell> */}
                    
                    

                    <TableCell align="center">
  <Switch {...label}  checked={!row?.isBlocked}
                            onChange={() =>
                              updateStatus(row?._id, row?.isBlocked)
                            }size="small" />
</TableCell>

                    <TableCell>
                     
                     
                      <Box className="table_actions">
                      <IconButton     onClick={() => navigate("/manage-category/details/"+row?._id)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => navigate("/manage-category/edit/"+row?._id)}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton onClick={() => {
  setSelectedId(row?._id);
  setOpen(true);
}}>
  <DeleteIcon />
</IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={categoryList}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="category"
      />
    
    </MainContainer>
  );
};

export default ManageCategory;
