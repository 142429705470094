import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  FormControl,
  NativeSelect,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useAddNotificationMutation } from "../../services/main";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showError, showToast } from "../../constants";
import { isString } from "../../utils/validations";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";

const AddNotification = () => {
  const navigate = useNavigate();
  const [addNotificationMutation] = useAddNotificationMutation();
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const formik = useFormik({
    initialValues: {
      title: "",
      message: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is required!")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      message: Yup.string()
        .required("Message is required!")
        .min(3, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        message: formik.values.message,
        title: formik.values.title,
      };

      try {
        const response = await addNotificationMutation(body).unwrap();
        if (response?.statusCode === 200) {
          showToast("Notification added successfully");
          navigate("/manage-notifications");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Notification Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      className="text_field"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Notification Message
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      className="text_field"
                      placeholder="Message"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    ></TextField>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Typography className="custom_label">
                      Select Platform
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <NativeSelect
                        fullWidth
                        defaultValue={0}
                        variant="outlined"
                        inputProps={{
                          name: "action",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={0}>Select</option>
                        <option value={10}>IOS</option>
                        <option value={20}>Android</option>
                        <option value={30}>All</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid> */}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
