import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  NativeSelect,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageRevenue = () => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(
    invoice: string,
    DateTime: string,
    PaymentMethod: string,
    TotalPayments: string
  ) {
    return { invoice, DateTime, PaymentMethod, TotalPayments };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("#00001", "25-12-2023 / 11:00a.m", "Card", "600 SAR"),
  ];
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Revenue</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Manage Payments" {...a11yProps(0)} />
              <Tab label="Manage Bank Account" {...a11yProps(1)} />
              <Tab label="Manage Service Fee" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <Checkbox {...label} />
                      </TableCell>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Invoice no</TableCell>
                      <TableCell>Buyer Details</TableCell>
                      <TableCell>Seller Details</TableCell>
                      <TableCell>Date/Time</TableCell>
                      <TableCell>Payment Method</TableCell>
                      <TableCell>Total Payments</TableCell>
                      <TableCell>Payments Status</TableCell>
                      <TableCell>Buyer Transfer Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">
                          <Checkbox {...label} />
                        </TableCell>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.invoice}</TableCell>
                        <TableCell>
                          Abdul Hussian <br />
                          abdulhussian67@gmail.com <br />
                          +971056734321
                        </TableCell>
                        <TableCell>
                          Seller name <br />
                          seller@gmail.com <br />
                          +971056734321
                        </TableCell>
                        <TableCell>{row.DateTime}</TableCell>
                        <TableCell>{row.PaymentMethod}</TableCell>
                        <TableCell>{row.TotalPayments}</TableCell>
                        <TableCell>
                          <FormControl>
                            <NativeSelect
                              defaultValue={10}
                              variant="outlined"
                              inputProps={{
                                name: "report",
                                id: "uncontrolled-native",
                              }}
                            >
                              <option value={0}>Select</option>
                              <option value={10}>Pending</option>
                              <option value={20}>Confirmed</option>
                            </NativeSelect>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            <NativeSelect
                              defaultValue={10}
                              variant="outlined"
                              inputProps={{
                                name: "report",
                                id: "uncontrolled-native",
                              }}
                            >
                              <option value={0}>Select</option>
                              <option value={10}>Pending</option>
                              <option value={20}>Confirmed</option>
                            </NativeSelect>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Bank Name</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="bankName"
                      variant="outlined"
                      fullWidth
                      placeholder="Bank Name"
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Account Holder’s Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="holderName"
                      variant="outlined"
                      fullWidth
                      placeholder="Account Holder’s Name"
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Branch Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="branchName"
                      variant="outlined"
                      fullWidth
                      placeholder="Branch Name"
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Bank Code</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="bankCode"
                      variant="outlined"
                      fullWidth
                      placeholder="Bank Code"
                    ></TextField>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Service Type
                    </Typography>
                    <FormControl fullWidth>
                      <NativeSelect
                        fullWidth
                        defaultValue={0}
                        variant="outlined"
                        inputProps={{
                          name: "report",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={0}>Select</option>
                        <option value={10}>Fixed</option>
                        <option value={20}>Commission</option>
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Service Fees
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="fees"
                      variant="outlined"
                      fullWidth
                      placeholder="10 SAR"
                    ></TextField>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageRevenue;
