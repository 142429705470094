import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";

const Faq = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const faqs = [
    {
      id: 1,
      question: "ques1",
      answer: "ans1",
    },
    {
      id: 1,
      question: "ques1",
      answer: "ans1",
    },
  ];
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<any>({
    isEdit: true,
    isDelete: true,
  });
 
  console.log("userdata",userData);
  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Products"
      );
      if (idx != -1) {
        let data = {
          isEdit: permissions[idx]?.isEdit,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  
    console.log(hidePermission, "hideeeeee");
  
    useEffect(() => {
      checkPermission();
    }, [userData]);
  return (
    <Grid container spacing={2}>
      {faqs?.map((item, index) => {
        return (
          <Grid key={index} item xs={12}>
            <Box className="faq_box">
              <FormControl sx={{ mb: 2, width: '100%' }}>
                <Typography className="custom_label">Question</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  value={item?.question}
                  placeholder="Question"
                ></TextField>
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <Typography className="custom_label">Answer</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  value={item?.answer}
                  placeholder="Answer"
                ></TextField>
              </FormControl>
              <Button variant="contained" className="btn btn_danger sm">Remove</Button>
            </Box>
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: '100%' }}>
            <Typography className="custom_label">Question</Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={question}
              onChange={(val) => setQuestion(val.target.value)}
              placeholder="Question"
            ></TextField>
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <Typography className="custom_label">Answer</Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={answer}
              onChange={(val) => setAnswer(val.target.value)}
              placeholder="Answer"
            ></TextField>
          </FormControl>
          <Button variant="contained" disabled={!question || !answer} className="btn btn_primary sm">{" "}Add{" "}</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Faq;
